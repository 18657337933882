import React from 'react';
import Moveable from 'react-moveable';
import { ref } from 'framework-utils';
import ParserStyle from 'style-to-object';

import fonts from '../../Fonts';

// acutal Moveable JS Library
import BannerElement from '../BannerElement';

import '../../styles.scss';

class BannerTextItem extends BannerElement {
  constructor(props) {
    super(props);
  }

  render() {
    const { target } = this.state;
    const { id, options, locked, container, offsetH, offsetW } = this.props;

    const { props } = this;

    const { onRenderStart, onDrag, onResize, onRotate, onEnd } = this;

    const elDefaultStyle = {
      fontSize: options.size,
      textAlign: options.position,
      color: options.color,
      fontFamily: `"${fonts.load(options.font)}"`,
      whiteSpace: 'pre-line',
      display: 'block',
    };

    const elDefaultClass = [`${props.id}`];

    const ElTag = `${options.tag}`;

    const elStyle = { ...elDefaultStyle, ...ParserStyle(options.style) };
    const elClass = `${elDefaultClass} ${options.class}`;

    return (
      <BannerElement {...props}>
        <Moveable
          ref={ref(this, 'moveable')}
          key={id}
          target={target}
          container={container}
          draggable={!locked}
          rotatable={!locked}
          resizable={true}
          scalable={false}
          edge={true}
          snappable={true}
          snapCenter={true}
          bounds={{ left: 0, top: 0, bottom: offsetH, right: offsetW }}
          origin={false}
          throttleDrag={1}
          throttleRotate={0.2}
          throttleResize={1}
          throttleScale={0.01}
          onRenderStart={onRenderStart}
          onDrag={onDrag}
          onRotate={onRotate}
          onResize={onResize}
          onDragEnd={onEnd}
          onResizeEnd={onEnd}
          onRotateEnd={onEnd}
        />

        <div
          id={id}
          onClick={this.onClick}
          className="moveable banner-item text-item"
        >
          <ElTag id={`${id}_el`} className={elClass} style={elStyle}>
            {options.content}
          </ElTag>
        </div>

        <div className="label" ref={ref(this, 'label')} />
      </BannerElement>
    );
  }
}

export default BannerTextItem;
