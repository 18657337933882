import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const FooterContainer = styled.footer`
  width: 100%;
  padding: 2rem 5rem;
  margin-top: ${(props) => (props.isLoggedIn ? 'auto' : '50px')};
`;

const FooterContent = styled.div`
  max-width: 1200px;
  display: flex;
  justify-content: ${(props) => (props.isLoggedIn ? 'flex-start' : 'center')};
  margin: ${(props) => (props.isLoggedIn ? '0' : '0 auto')};
  align-items: center;
  gap: 20px;

  a {
    color: gray;
    text-decoration: none;
    font-size: ${(props) => (props.isLoggedIn ? '14px' : '12px')};

    &:hover {
      text-decoration: underline;
    }
  }
`;

const Footer = ({ user }) => {
  return (
    <FooterContainer isLoggedIn={!!user}>
      <FooterContent isLoggedIn={!!user}>
        <Link to="/privacy-policy">Privacy Policy</Link>
      </FooterContent>
    </FooterContainer>
  );
};

export default Footer;
