import React, { Component } from 'react';
import FormStyles from './styles/FormStyles';
import Title from './styles/TitleStyle';
import { Link } from 'react-router-dom';
import ExtendedWrapperFromModalStyles from './styles/ExtendedWrapperFromModalStyles';
import DefaultLayout from './DefaultLayout';
import fetchInstance from '../utils/fetchInstance';

export default class Signup extends Component {
  constructor() {
    super();
    this.state = {
      login: {
        email: '',
        password: '',
      },
      busy: false,
    };
  }

  handleInput = (e) => {
    const field = e.target.name;
    const section = { ...this.state.login };
    section[field] = e.target.value;

    return this.setState({ login: section });
  };
  login = (e) => {
    e.preventDefault();

    this.setState({ busy: true });
    const user = { ...this.state.login };
    fetchInstance('/login', {
      method: 'post',
      body: JSON.stringify(user),
    })
      .then((res) => {
        if (res.ok) return res.json();
        return res.json().then((data) => {
          throw new Error(data.message || res.status + ' ' + res.statusText);
        });
      })
      .then((data) => {
        if (data.success) {
          localStorage.setItem('token', data.token);
          this.props.setUser(data.user);
          this.props.setBanner(true, 'good', 'Logged In!');
        }
        if (data.error) {
          this.props.setBanner(true, 'bad', data.message);
        }

        this.setState({ busy: false });
      })
      .catch((err) => {
        this.setState({ busy: false });
        return this.props.setBanner(true, 'bad', err.message, false);
      });
  };

  render() {
    return (
      <DefaultLayout>
        <ExtendedWrapperFromModalStyles>
          <FormStyles onSubmit={this.login}>
            <Title>Log in</Title>

            <div className="group">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                name="email"
                value={this.state.login.email}
                onChange={this.handleInput}
                required
              />
            </div>

            <div className="group">
              <label htmlFor="password">Password</label>
              <input
                type="password"
                name="password"
                value={this.state.login.password}
                onChange={this.handleInput}
                required
              />
            </div>

            <div
              style={{
                display: 'flex',
                marginTop: '20px',
                borderTop: '1px solid #e0e0e0',
                paddingTop: '5px',
              }}
            >
              <Link to="/sign-up">Register</Link>
              <Link style={{ marginLeft: 'auto' }} to="/reset-password">
                Forgot password?
              </Link>
            </div>

            <div style={{ textAlign: 'center', marginTop: '30px' }}>
              <button type="submit" className="confirm-button">
                Log in
              </button>
            </div>
          </FormStyles>
        </ExtendedWrapperFromModalStyles>
      </DefaultLayout>
    );
  }
}
