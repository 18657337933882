import config from '../config'

const fetchFbInstance = async (url, options = {}) => {
  const token = localStorage.getItem('token')
  const defaultHeaders = {
    'Content-Type': 'application/json',
    ...(token && { Authorization: `Bearer ${token}` }),
  }

  const defaultOptions = {
    headers: { ...defaultHeaders, ...options.headers },
    ...options,
  }

  const response = await fetch(`${config.apiFbUrl}${url}`, defaultOptions)

  return response
}

export default fetchFbInstance
