import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import DefaultLayout from '../DefaultLayout';
import CampaignPreviewStyles from '../styles/CampaignPreviewStyles';
import Title from '../styles/TitleStyle';
import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css';
import UploadBannerArchive from './UploadBannerArchive';
import ManageGroupsModal from './ManageGroupsModal';
import { GeneralModalContext } from '../GeneralModal';
import CampaignImage from './CampaignImage';
import { MdClose } from 'react-icons/md';
import GeneralModalStyles from '../styles/GeneralModalStyles';
import MixerModal from '../MixerModal';
import FacebookStepOne from './FacebookStepOne';
import Select from 'react-select';
import { IoArrowBack } from 'react-icons/io5';
import spinner from '../../images/spinner.svg';
import { CampaignSearchBar } from '../CampaignsMain';

import CampaignCard from './CampaignCard';
import CampaignDetails from './CampaignDetails';
import ShareCampaign from './ShareCampaign';
import DeleteModal from '../Modals/DeleteModal';
import fetchInstance from '../../utils/fetchInstance';

const style = {
  display: 'flex',
  flexWrap: 'wrap',
  overflowY: 'auto',
  margin: 0,
  padding: 0,
  marginRight: '-3rem',
};

const CampaignTitleSection = ({ activeCampaign, parent }) => (
  <>
    {activeCampaign.client && (
      <Link
        to={`/client/${activeCampaign.client._id}`}
        style={{ border: '0', fontSize: '20px', fontWeight: 'bold' }}
      >
        <IoArrowBack style={{ transform: 'translateY(3px)' }} />
        Back
      </Link>
    )}
    <Title>
      {activeCampaign.client && (
        <Link
          to={`/client/${activeCampaign.client._id}`}
          style={{ color: '#52bad5' }}
        >
          {activeCampaign.client.name} -&nbsp;
        </Link>
      )}
      {activeCampaign.parent && (
        <Link
          to={`/campaign/${activeCampaign.parent}`}
          style={{ color: '#52bad5' }}
        >
          {parent.name} -&nbsp;
        </Link>
      )}
      {activeCampaign.name}
    </Title>
  </>
);

const CampaignPreview = ({ props, match, extractCSRF, setBanner, user }) => {
  const [activeCampaign, setActiveCampaign] = useState(false);
  const [initialCampaignImages, setInitialCampaignImages] = useState(false);
  const [campaignImages, setCampaignImages] = useState(false);
  const [campaignSortedImages] = useState(false);
  const [campaignErrorMsg, setCampaignErrorMsg] = useState('');
  const [campaignGroups, setCampaignGroups] = useState([]);
  const [campaignOriginalGroups, setCampaignOriginalGroups] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState('');
  const [allSelected, setAllSelected] = useState(false);
  const [picked, setPicked] = useState([]);
  const [addToGroupTarget, setAddToGroupTarget] = useState('');
  const [contrast, setContrast] = useState(1);
  const [batchId, setBatchId] = useState(false);
  const [selectImagesType, setSelectImagesType] = useState('fixed');
  const [selectImagesAmount, setSelectImagesAmount] = useState('');
  const [toProcess, setToProcess] = useState(false);
  const [displayCode, setDisplayCode] = useState(false);

  const [subCampaigns, setSubCampaigns] = useState([]);
  const [isCampaignGroup, setIsCampaignGroup] = useState(false);
  const [parent, setParent] = useState([]);
  const [selectedCampaign, setSelectedCampaign] = useState(null);

  const { showModal, handleShowModal, handleCloseModal } = useContext(
    GeneralModalContext,
  );
  const [modalType, setModalType] = useState();

  let isPublic = false;
  if (match.params.shared) {
    isPublic = true;
  }
  const campaignId = match.params.id;

  useEffect(() => {
    (async () => {
      const campaign = await fetchCampaign();
      setActiveCampaign(campaign);
    })();
  }, []);

  useEffect(() => {
    let mounted = true;

    const subCampaigns = async () => await fetchSubCampaigns();

    if (activeCampaign) {
      subCampaigns().then((items) => {
        if (mounted) {
          setSubCampaigns(items);
        }
      });
    }

    return () => {
      mounted = false;
    };
  }, [activeCampaign, selectedCampaign]);

  useEffect(() => {
    let mounted = true;

    const parentCampaign = async (parent) => await fetchCampaign(parent);

    if (activeCampaign && activeCampaign.parent) {
      parentCampaign(activeCampaign.parent).then((campaign) => {
        if (mounted) {
          setParent(campaign);
        }
      });
    }

    return () => {
      mounted = false;
    };
  }, [activeCampaign]);

  useEffect(() => {
    (async () => {
      if (activeCampaign) {
        if (activeCampaign.projectID) {
          if (isPublic) {
            await fetchPublicImages(activeCampaign.projectID);
          } else {
            await fetchImages(activeCampaign.projectID);
          }
        } else {
          setCampaignImages([]);
          setCampaignErrorMsg('Sorry, no images found for this project');
        }
      }
    })();

    // (async () => {
    //     if (activeCampaign.parent) {
    //         const parentCampaign = await fetchCampaign(activeCampaign.parent);
    //         setParent(parentCampaign);
    //     }
    // })();
  }, [activeCampaign]);

  useEffect(() => {
    checkScores();
  }, [initialCampaignImages]);

  let justOnce = true;
  const checkScores = () => {
    if (campaignImages && batchId) {
      let tempCampaignImages = [...initialCampaignImages];

      let params = { batch: [batchId] };
      params.per_page = 1000;

      fetchInstance('/getFilesAI', {
        method: 'POST',
        body: JSON.stringify(params),
      })
        .then((res) => {
          if (!res.ok) {
            throw new Error(res.statusText);
          }
          return res.json();
        })
        .then(async (data) => {
          if (data.images.length) {
            for (let i = 0; i < data.images.length; i++) {
              for (let j = 0; j < tempCampaignImages.length; j++) {
                // let externalLocation = data.images[i].external_location.split("/").splice(3).join("/")
                // if (externalLocation.indexOf(campaignImages[j].image.substring(2)) !== -1) {
                //     tempCampaignImages[j].score = data.images[i].average_grade;
                // }
                if (
                  data.images[i].external_location.indexOf(
                    tempCampaignImages[j].image.substring(2),
                  ) !== -1
                ) {
                  tempCampaignImages[j].score = data.images[i].average_grade;
                }
              }
            }

            setCampaignImages(tempCampaignImages);

            setToProcess(data.processing);

            if (data.processing !== 0) {
              await timeout(2000);
              checkScores();
            }

            if (data.processing === 0 && justOnce) {
              await timeout(2000);
              checkScores();
              justOnce = false;
            }
          }
        });
    }
  };

  const timeout = (delay) => {
    return new Promise((res) => setTimeout(res, delay));
  };

  const fetchCampaign = async (cId = null) => {
    const campaign = cId || campaignId;
    try {
      const path = `/getCampaign/${campaign}${isPublic ? '/shared' : ''}`;
      const res = await fetchInstance(path, {
        method: 'GET',
      });

      return await res.json();
    } catch (res) {
      setBanner(true, 'bad', res.message, false);
      throw new Error(res.status + ' ' + res.statusText);
    }
  };

  const fetchSubCampaigns = async () => {
    if (activeCampaign.isCampaignGroup) {
      try {
        const res = await fetchInstance('/getClientCampaigns', {
          method: 'POST',
          body: JSON.stringify({
            id: activeCampaign.client._id,
            parent: activeCampaign._id,
          }),
        });
        return await res.json();
      } catch (res) {
        setBanner(true, 'bad', res.message, false);
        throw new Error(res.status + ' ' + res.statusText);
      }
    }
  };

  const fetchImages = (projectID) => {
    return fetchInstance(`/check/${projectID}`)
      .then((res) => {
        if (!res.ok) {
          throw new Error(res.statusText);
        }
        return res.json();
      })
      .then((data) => {
        if (data.status) {
          if (data.status.current === 'mixing') {
            props.history.push(`/combinations?pid=${this.project}`);
          } else if (data.status.current !== 'done') {
            setCampaignImages([]);
            setCampaignErrorMsg('This campaign has no banners.');
            // return setBanner(true, "bad", "This campaign has no banners.");
          } else {
            const campaignSettings = data.combinations[0];
            const infoObject = data.combinations.shift(); // get rid of the first empty object used as general data store;
            if (
              infoObject &&
              infoObject.status &&
              infoObject.status.errors.length
            ) {
              setBanner(true, 'bad', 'There were errors generating banners.');
            }
            const images = data.combinations.map((image, i) => {
              // in case the project was re-uploaded, Combinations.json still has the old path for images,
              // so we need to change that using the current project ID from the page
              const url = image.image;

              let urlToBeUsed = url;
              image.image = urlToBeUsed;
              return image;
            });

            const rawGroups = infoObject.groups || [];
            setCampaignOriginalGroups(rawGroups);
            const groups = rawGroups.map((group) => {
              if (!group.color) {
                group.color =
                  '#' +
                  (
                    'FFFFFF' + Math.floor(Math.random() * 16777215).toString(16)
                  ).slice(-6);
              }

              if (group.images && group.images.length) {
                group.images = new Set([...group.images]); // this is because the stringifying ruins sets
              } else {
                group.images = new Set([]);
              }
              return group;
            });

            const ungrouped = images.filter((image) => {
              let isUngrouped = true;
              rawGroups.forEach((group) => {
                if (group.images.length) return;
                group.images.forEach((groupedImage) => {
                  if (image.image === groupedImage.image) isUngrouped = false;
                });
              });
              return isUngrouped;
            });

            const [rejected] = groups.filter(
              (group) => group.name === 'Rejected',
            );
            if (!rejected) {
              groups.unshift({ name: 'Rejected' });
            }

            groups.unshift({ name: 'Ungrouped', images: new Set(ungrouped) });

            // let errors = null;
            // if (infoObject && infoObject.status && infoObject.status.errors.length) {
            //     errors = infoObject.status.errors;
            // }

            if (campaignSettings.batch_id) {
              setBatchId(campaignSettings.batch_id);
            }

            setCampaignImages(images);
            setInitialCampaignImages(images);
            setCampaignGroups(groups);
          }
        } else if (data.error) {
          // there was a problem and the project never finished
          // setBanner(true, "bad", data.message, true);
          // project has no images yet
          setCampaignImages([]);
          setCampaignErrorMsg(data.message);
        }
      })
      .catch((err) => {
        setBanner(true, 'bad', err.message, true);
      });
  };

  const fetchPublicImages = () => {
    return fetchInstance('/getPublicImages', {
      method: 'POST',
      body: JSON.stringify({ link: campaignId }),
    })
      .then((res) => {
        if (!res.ok) throw new Error(res.statusText);
        return res.json();
      })
      .then((data) => {
        if (data.error) throw new Error(data.message);
        const infoObject = data.combinations.shift(); // get rid of the first empty object used as general data store;
        // if (!this.project) this.project = infoObject.projectID; // wtf is this?
        const images = data.combinations.map((image, i) => {
          // in case the project was re-uploaded, Combinations.json still has the old path for images,
          // so we need to change that using the current project ID from the page
          const url = image.image;
          let urlToBeUsed = url;
          image.image = urlToBeUsed;
          return image;
        });

        const rawGroups = infoObject.groups || [];
        const groups = rawGroups.map((group) => {
          group.color =
            '#' +
            (
              'FFFFFF' + Math.floor(Math.random() * 16777215).toString(16)
            ).slice(-6);
          if (group.images.length) {
            group.images = new Set([...group.images]); // this is because the stringifying ruins sets
          } else {
            group.images = new Set([]);
          }
          return group;
        });

        const ungrouped = images.filter((image) => {
          let isUngrouped = true;
          rawGroups.forEach((group) => {
            if (group.images.length) return;
            group.images.forEach((groupedImage) => {
              if (image.image === groupedImage.image) isUngrouped = false;
            });
          });
          return isUngrouped;
        });

        const [rejected] = groups.filter((group) => group.name === 'Rejected');
        if (!rejected) {
          groups.unshift({ name: 'Rejected', images: new Set() });
        }

        groups.unshift({ name: 'Ungrouped', images: new Set(ungrouped) });

        setCampaignImages(images);
        setInitialCampaignImages(images);
        setCampaignGroups(groups);
      })
      .catch((err) => {
        setBanner(true, 'bad', err.message, true);
      });
  };

  const selectGroup = (groupName) => {
    const group = campaignGroups.filter((group) => group.name === groupName)[0];
    setSelectedGroup(group);
  };

  const selectAllHandler = (e) => {
    if (allSelected) {
      setPicked([]);
      setAllSelected(false);
    } else {
      let tempPicked;
      if (selectedGroup) {
        tempPicked = [...selectedGroup.images];
      } else {
        tempPicked = [...campaignImages];
      }

      setPicked(tempPicked);
      setAllSelected(true);
    }
  };

  const pickImage = (clickedImage) => {
    const [imageObject] = campaignImages.filter(
      (image) => image.image === clickedImage,
    );
    const fullImage = { ...imageObject };
    let tempPicked = [...picked];
    let wasPicked = tempPicked.filter((image) => image.image === clickedImage)
      .length;
    if (wasPicked) {
      tempPicked = tempPicked.filter((image) => image.image !== clickedImage);
    } else {
      tempPicked.push(fullImage);
    }
    const allSelected =
      tempPicked.length === campaignImages.length &&
      campaignImages.length !== 0;

    setPicked(tempPicked);
    setAllSelected(allSelected);
  };

  const rejectImage = (e) => {
    e.stopPropagation();
    const clickedImage = e.currentTarget.dataset.image;
    const [imageObject] = campaignImages.filter(
      (image) => image.image === clickedImage,
    );
    const fullImage = { ...imageObject };
    let tempPicked = [...picked];
    let rejected = null;
    if (campaignGroups) {
      rejected = campaignGroups.filter((group) => group.name === 'Rejected')[0];
    }
    // Go through both the currently picked images and the full rejected group
    //  to see if image was previously rejected - though just the rejected group itself should be itself enough
    let wasAlreadyRejected = tempPicked.filter(
      (image) => image.image === clickedImage,
    ).length;
    if (!wasAlreadyRejected && rejected) {
      wasAlreadyRejected = [...rejected.images].filter(
        (image) => image.image === clickedImage,
      ).length;
    }
    if (wasAlreadyRejected) {
      tempPicked = tempPicked.filter((image) => image.image !== clickedImage);
    } else {
      tempPicked.push(fullImage);
    }

    if (!wasAlreadyRejected) {
      addToGroup(null, fullImage);
    } else {
      removeFromGroup(null, fullImage);
    }
  };

  const addToGroup = (ev, image) => {
    let groupName = addToGroupTarget;
    if (isPublic) {
      groupName = 'Rejected';
    }
    if (!groupName) {
      return setBanner(true, 'bad', 'Please select a group and try again');
    }
    const selectedImages = image ? [image] : [...picked];
    if (!selectedImages.length) {
      return setBanner(
        true,
        'bad',
        'Please select at least one image and try again',
      );
    }
    // Add to group
    const groups = [...campaignGroups];
    const groupObject = groups.filter((group) => group.name === groupName)[0];
    const index = groups.indexOf(groupObject);
    const group = { ...groupObject };
    if (!group.name) {
      return setBanner(true, 'bad', 'Could not find selected group');
    }
    const groupImages = group.images || new Set();
    selectedImages.forEach((image) => {
      const groupImagesArr = [...groupImages];
      const [found] = groupImagesArr.filter(
        (existingImage) => existingImage.id === image.id,
      );
      if (!found) {
        groupImages.add(image);
      }
    });
    group.images = groupImages;
    // If modifying the Rejected group log the action
    if (group.name === 'Rejected') {
      const log = group.log || [];
      selectedImages.forEach((image) => {
        log.unshift(
          `${user && user.name ? user.name + ' ' : ''}Rejected image ${image.image.split('/__screenshots/')[1]
          } on ${new Date().toString().slice(0, 25)}`,
        );
      });
      while (log.length > 200) {
        log.pop();
      }
      group.log = log;
    }

    groups.splice(index, 1, group);
    // Remove from ungrouped
    const [ungrouped] = groups.filter((group) => group.name === 'Ungrouped');
    let ungroupedImages = [...ungrouped.images];
    selectedImages.forEach((selectedImage) => {
      ungroupedImages = ungroupedImages.filter(
        (ungroupedImage) => selectedImage.image !== ungroupedImage.image,
      );
    });
    ungrouped.images = new Set(ungroupedImages);
    setCampaignGroups(groups);
    if (group.name === 'Ungrouped') return;
    const projectID = activeCampaign.projectID;
    // Save the grouping on the server
    fetchInstance('/addOrUpdateGroup', {
      method: 'POST',
      body: JSON.stringify({
        projectID,
        group: {
          name: group.name,
          images: [...group.images],
          log: group.log ? [...group.log] : [],
        },
      }),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
      })
      .then((data) => {
        if (data.error) {
          return setBanner(true, 'bad', data.message);
        }

        setPicked([]);
      });
  };

  const removeFromGroup = (ev, image) => {
    let groupName = addToGroupTarget;
    if (isPublic) {
      groupName = 'Rejected';
    }
    if (!groupName) {
      return setBanner(true, 'bad', 'Please select a group and try again');
    }
    let selectedImages;
    if (image) {
      selectedImages = [image];
    } else {
      selectedImages = [...picked];
    }
    if (!selectedImages.length) {
      return setBanner(
        true,
        'bad',
        'Please select at least one image and try again',
      );
    }
    // Remove from group
    let groups = [...campaignGroups];
    const groupObject = groups.filter((group) => group.name === groupName)[0];
    const index = groups.indexOf(groupObject);
    const group = { ...groupObject };
    if (!group.name) {
      return setBanner(true, 'bad', 'Could not find selected group');
    }
    selectedImages.forEach((image) => {
      let found;
      for (let i of group.images) {
        if (image.image === i.image) found = i;
      }
      group.images.delete(found);
    });
    groups.splice(index, 1, group);
    // Redo ungrouped

    groups = groups.filter((group) => group.name !== 'Ungrouped');
    const images = [...campaignImages];
    const ungrouped = images.filter((image) => {
      let isUngrouped = true;
      groups.forEach((group) => {
        if (!group.images) {
          group.images = new Set([]);
        } else {
          group.images = new Set([...group.images]);
        }
        if (group.images.length) return; // makes sure it's a Set which always has length 0 and not an array
        group.images.forEach((groupedImage) => {
          if (image.image === groupedImage.image) isUngrouped = false;
        });
      });

      return isUngrouped;
    });
    groups.unshift({ name: 'Ungrouped', images: new Set(ungrouped) });
    setCampaignGroups(groups);

    const projectID = activeCampaign.projectID;
    if (group.name === 'Ungrouped') {
      return;
    }
    if (group.name === 'Rejected') {
      const log = group.log || [];

      selectedImages.forEach((image) => {
        log.unshift(
          `${user && user.name ? user.name + ' ' : ''}ACCEPTED image ${image.image.split('/__screenshots/')[1]
          } on ${new Date().toString().slice(0, 25)}`,
        );
      });
      while (log.length > 200) {
        log.pop();
      }
      group.log = log;
    }
    // Save the grouping on the server
    fetchInstance('/addOrUpdateGroup', {
      method: 'POST',
      body: JSON.stringify({
        projectID,
        group: {
          name: group.name,
          images: [...group.images],
          log: group.log ? [...group.log] : [],
        },
      }),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
      })
      .then((data) => {
        if (data.error) {
          return setBanner(true, 'bad', data.message);
        }

        setPicked([]);
      });
  };

  const selectContrast = (ev) => {
    let tempPicked;
    const threshold = parseFloat(contrast);
    if (selectedGroup) {
      tempPicked = [
        ...selectedGroup.images.filter((image) => {
          if (!image.meta) {
            return true;
          }
          if (image.meta.contrast >= threshold) {
            return true;
          }
          return false;
        }),
      ];
    } else {
      tempPicked = [
        ...campaignImages.filter((image) => {
          if (!image.meta) {
            return true;
          }
          if (image.meta.contrast >= threshold) {
            return true;
          }
          return false;
        }),
      ];
    }

    setPicked(tempPicked);
  };

  const saveGroups = (newGroups) => {
    const projectID = activeCampaign.projectID;

    fetchInstance('/addOrUpdateGroups', {
      method: 'POST',
      body: JSON.stringify({ projectID, groups: newGroups }),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
      })
      .then((data) => {
        if (data.success) {
          fetchImages(activeCampaign.projectID);
        }

        if (data.error) {
          return setBanner(true, 'bad', data.message);
        }
      });
  };

  const deleteGroup = (groupName) => {
    let groups = campaignGroups.filter(
      (group) => group.name !== groupName && group.name !== 'Ungrouped',
    );

    const images = [...campaignImages];
    const ungrouped = images.filter((image) => {
      let isUngrouped = true;
      groups.forEach((group) => {
        if (!group.images) {
          group.images = new Set([]);
        } else {
          group.images = new Set([...group.images]);
        }
        if (group.images.length) {
          return;
        } // makes sure it's a Set which always has length 0 and not an array
        group.images.forEach((groupedImage) => {
          if (image.image === groupedImage.image) isUngrouped = false;
        });
      });

      return isUngrouped;
    });
    groups.unshift({ name: 'Ungrouped', images: new Set(ungrouped) });

    setCampaignGroups(groups);

    const projectID = activeCampaign.projectID;
    fetchInstance('/deleteGroup', {
      method: 'POST',
      body: JSON.stringify({
        projectID,
        group: { name: groupName, images: new Set([]) },
      }),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error(
            'Something went wrong. Refresh the page and try again',
          );
        }
      })
      .then((data) => {
        if (data.error) {
          return setBanner(true, 'bad', data.message);
        }
      })
      .catch((err) => setBanner(true, 'bad', err.message));
  };

  const renderImages = () => {
    let images;

    if (selectedGroup && selectedGroup.images) {
      images = [...selectedGroup.images];
    } else if (selectedGroup) {
      images = [];
    } else {
      images = [...campaignImages];
    }

    return images.map((image, i) => {
      // in case the project was re-uploaded, Combinations.json still has the old path for images,
      // so we need to change that using the current project ID from the page
      const url = image.image;
      let urlToBeUsed = url;

      // get which groups and the colors the image belongs to
      const colors = [];
      let rejected = false;
      campaignGroups.forEach((group) => {
        let found = false;
        if (group.images && group.images.size) {
          for (let pic of group.images) {
            if (image.image === pic.image) {
              found = true;
              if (group.name === 'Rejected') {
                rejected = true;
              }
              break;
            }
          }
        }
        if (found) colors.push(group.color);
      });
      let tempPicked;
      for (let o of picked) {
        if (o.image === image.image) {
          tempPicked = true;
        }
      }

      return (
        <CampaignImage
          url={urlToBeUsed}
          key={i}
          name={image.number}
          score={image.score}
          image={image}
          picked={tempPicked}
          pickImage={isPublic ? rejectImage : pickImage}
          colors={colors}
          rejected={rejected}
        />
      );
    });
  };

  const sendToArchive = (e) => {
    const imageObjectsToArchive = [...picked];

    if (!imageObjectsToArchive.length) {
      return setBanner(
        true,
        'bad',
        'Please select at least one image to download',
      );
    }

    const imagesToArchive = imageObjectsToArchive.map(
      (imageObject) => imageObject.image,
    );
    setBanner(true, 'good', 'Preparing archive...', true);

    fetchInstance('/pls4toarchive', {
      method: 'POST',
      body: JSON.stringify({
        projectID: activeCampaign.projectID,
        imagesToArchive,
      }),
    })
      .then((res) => {
        if (res.ok) {
          return res.blob();
        } else {
          return res.json().then((data) => {
            throw new Error(data.message);
          });
        }
      })
      .then((blob) => {
        const file = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = file;
        link.download = 'archive.zip';
        link.click();
        setBanner(true, 'good', 'Downloading...');
      })
      .catch((err) => setBanner(true, 'bad', err.message, false));
  };

  const getAIScores = () => {
    let generatedBatchId = Math.random().toString(36).substr(2, 9);

    fetchInstance('/prepareFilesForAI', {
      method: 'POST',
      body: JSON.stringify({
        picked,
        batch_id: generatedBatchId,
        projectID: activeCampaign.projectID,
      }),
    })
      .then((res) => {
        if (!res.ok) throw new Error(res.statusText);
        return res.json();
      })
      .then((data) => {
        if (data.error) throw new Error(data.message);
      })
      .then((data) => { })
      .catch((err) => {
        console.log(err);
      });

    let data = [];
    for (let image in initialCampaignImages) {
      data.push({ path: initialCampaignImages[image].image });
    }

    fetchInstance('/sendFilesAI', {
      method: 'POST',
      body: JSON.stringify({
        files: data,
        batch_id: generatedBatchId,
      }),
    })
      .then((res) => {
        if (res.ok) return res.json();
        throw new Error(res.status + ' ' + res.statusText);
      })
      .then((data) => {
        if (data.success) {
          setBanner(
            true,
            'good',
            'Campaign images successfully sent to A.I. for processing',
          );
          checkScores();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const saveCampaign = (campaign) => {
    new Promise((resolve, reject) => {
      resolve(
        fetchInstance('/campaign', {
          method: 'put',
          body: JSON.stringify({ campaign: campaign }),
        }),
      );
    })
      .then((res) => {
        if (!res.ok) throw new Error(res.statusText);
        return res.json();
      })
      .then((data) => {
        if (data.success) return data.campaign;
        if (data.error) throw new Error(data.message);
      })
      .then((updatedCampaign) => {
        setActiveCampaign(updatedCampaign);

        setBanner(true, 'good', 'Campaign updated successfully');
      })
      .catch((err) => {
        // this.setState({ previewBusy: false };
        setBanner(true, 'bad', err.message, false);
      });
  };

  const copyScript = () => {
    const copyText = document.getElementById('textareaScript');
    if (copyText) {
      copyText.select();
      copyText.setSelectionRange(0, 99999);
      navigator.clipboard.writeText(copyText.value).then();
    }
  };
  const getCode = () => {
    fetchInstance('/generatecode', {
      method: 'post',
    })
      .then((res) => {
        if (!res.ok) throw new Error(res.statusText);
        return res.json();
      })
      .then((data) => {
        if (data.success) {
          setDisplayCode(data.code);
        }
        if (data.error) throw new Error(data.message);
      })
      .catch((err) => {
        setBanner(true, 'bad', err.message, false);
      });
  };

  const selectImages = () => {
    let tempPicked = [];

    if (selectImagesAmount !== '') {
      switch (selectImagesType) {
        case 'fixed':
          for (let i = 0; i < campaignSortedImages.length; i++) {
            if (i >= parseInt(selectImagesAmount)) {
              break;
            }
            tempPicked.push(campaignSortedImages[i]);
          }
          break;
        case 'percent':
          let amountToSelect = (
            (selectImagesAmount / 100) *
            campaignSortedImages.length
          ).toFixed(3);
          for (let i = 0; i < amountToSelect; i++) {
            tempPicked.push(campaignSortedImages[i]);
          }
          break;
      }
    }

    setPicked(tempPicked);
  };

  let UploadBannerArchiveModal, ManageGroups, MixBanners, SendToFacebook;
  const openUploadArchiveModal = () => {
    setModalType('upload-banner-archive');
    handleShowModal();
  };

  if (showModal && modalType === 'upload-banner-archive') {
    UploadBannerArchiveModal = (
      <UploadBannerArchive
        extractCSRF={extractCSRF}
        setBanner={setBanner}
        activeCampaign={activeCampaign}
        handleCloseModal={handleCloseModal}
        saveCampaign={saveCampaign}
      />
    );
  }

  const openManageGroupsModal = () => {
    setModalType('manage-groups-modal');
    handleShowModal();
  };

  if (showModal && modalType === 'manage-groups-modal') {
    ManageGroups = (
      <ManageGroupsModal
        setBanner={setBanner}
        handleCloseModal={handleCloseModal}
        campaignGroups={campaignOriginalGroups}
        saveGroups={saveGroups}
      />
    );
  }

  const openMixerModal = () => {
    setModalType('mixer-modal');
    handleShowModal();
  };

  if (showModal && modalType === 'mixer-modal') {
    MixBanners = (
      <GeneralModalStyles className="modal-wrapper">
        <div className="modal-body" style={{ width: '700px' }}>
          <div onClick={handleCloseModal} className="modal-close">
            <MdClose className="action-button button-delete" />
          </div>

          <MixerModal
            setBanner={setBanner}
            extractCSRF={extractCSRF}
            cid={activeCampaign._id}
          />
        </div>
      </GeneralModalStyles>
    );
  }

  const openFacebookModal = () => {
    setModalType('facebook-modal');
    handleShowModal();
  };

  if (showModal && modalType === 'facebook-modal') {
    SendToFacebook = (
      <GeneralModalStyles className="modal-wrapper">
        <div className="modal-body">
          <div onClick={handleCloseModal} className="modal-close">
            <MdClose className="action-button button-delete" />
          </div>

          <FacebookStepOne
            setBanner={setBanner}
            extractCSRF={extractCSRF}
            campaignId={campaignId}
          />
        </div>
      </GeneralModalStyles>
    );
  }

  const options = [
    { value: 'default', label: 'Default' },
    { value: 'ai-scores', label: 'Sort by A.I. scores' },
  ];

  const selectStyles = {
    control: (base) => ({
      ...base,
      border: '1px solid #e0e0e0',
      outline: 'none',
      boxShadow: 'none',
      fontSize: '14px',
      width: '175px',
      cursor: 'pointer',
      marginTop: '-10px',
    }),
  };

  const orderGallery = (e) => {
    switch (e.value) {
      case 'default':
        setCampaignImages(initialCampaignImages);
        break;
      case 'ai-scores':
        let obj = [...campaignImages];

        let sortedObjKeys = Object.keys(obj).sort(function (keyA, keyB) {
          return obj[keyB].score - obj[keyA].score;
        });
        let sortedObj = [];
        for (let i = 0; i < sortedObjKeys.length; i++) {
          sortedObj.push(obj[sortedObjKeys[i]]);
        }

        setCampaignImages(sortedObj);

        renderImages();
        break;
    }
  };

  const handleSearchCampaignInput = (ev) => {
    const txt = ev.target.value.toLowerCase();
    const newCampaigns = [...subCampaigns];
    const visibleCampaigns = newCampaigns.filter((client) => {
      if (
        (client.name && client.name.toLowerCase().includes(txt)) ||
        (client.notes && client.notes.toLowerCase().includes(txt))
      ) {
        return client;
      }
    });

    setSubCampaigns(visibleCampaigns);
  };

  if (activeCampaign && !activeCampaign.isCampaignGroup) {
    return (
      <DefaultLayout>
        <CampaignPreviewStyles>
          <CampaignTitleSection
            activeCampaign={activeCampaign}
            parent={parent}
          />
          <div className="campaign-controls-main">
            <div className="campaign-controls-main_first">
              <Link
                to={`/banner-creator?cid=${activeCampaign._id}`}
                className="confirm-button"
              >
                Create Banners
              </Link>
              <button
                type="button"
                className="confirm-button"
                onClick={openMixerModal}
              >
                Mix Banners
              </button>
              <button
                type="button"
                className="confirm-button"
                onClick={openUploadArchiveModal}
              >
                Upload Archive
              </button>
            </div>

            <div className="campaign-controls-main_second">
              {user.googleAccount && user.googleAccount.name && (
                <Link
                  data-testid="google-link"
                  to={`/googleCampaign/${activeCampaign._id}`}
                  className="confirm-button"
                >
                  Send to Google
                </Link>
              )}
              {user.facebookAccount && user.facebookAccount.name && (
                <Link
                  data-testid="google-link"
                  to={`/facebookCampaign/${activeCampaign._id}`}
                  className="confirm-button"
                >
                  Send to Facebook
                </Link>
                // <button
                //     type="button"
                //     className="confirm-button"
                //     onClick={openFacebookModal}
                // >
                //     Send to Facebook
                // </button>
              )}
            </div>
          </div>

          <div className="gallery-wrapper">
            {activeCampaign.projectID && (
              <div className="gallery-controls">
                <ul data-testid="groups-list" className="galleryGroupsList">
                  <li
                    className={!selectedGroup ? 'selected' : ''}
                    onClick={() => {
                      selectGroup();
                    }}
                  >
                    <div className="group-name">All</div>
                  </li>
                  {campaignGroups.map((group, i) => (
                    <li
                      key={i}
                      onClick={() => {
                        selectGroup(group.name);
                      }}
                      className={
                        selectedGroup && selectedGroup.name === group.name
                          ? 'selected'
                          : ''
                      }
                    >
                      {group.name === 'Rejected' ? (
                        <MdClose className="rejected-icon" />
                      ) : (
                        <div
                          className="colorIcon"
                          style={{ backgroundColor: group.color }}
                        />
                      )}
                      <div className="group-name">{group.name}</div>
                    </li>
                  ))}
                </ul>

                <div>
                  <div style={{ float: 'left', marginRight: '20px' }}>
                    {batchId && toProcess === 0 && (
                      <Select
                        className="filter-client"
                        styles={selectStyles}
                        name="form-field-name"
                        options={options}
                        defaultValue={options[0]}
                        onChange={orderGallery}
                      />
                    )}
                  </div>

                  <button
                    style={{ padding: '12px 18px', marginTop: '-1px' }}
                    className="secondary-button"
                    onClick={getAIScores}
                    data-testid="contrast-button"
                  >
                    Get A.I. scores
                  </button>
                </div>
              </div>
            )}

            <div className="gallery" data-testid="gallery">
              {campaignImages ? (
                campaignImages.length !== 0 ? (
                  renderImages()
                ) : (
                  <h1 style={{ textAlign: 'center' }}>{campaignErrorMsg}</h1>
                )
              ) : (
                <div className="loader" style={{ textAlign: 'center' }}>
                  <img src={spinner} alt="" />
                </div>
              )}
            </div>
          </div>

          {activeCampaign.projectID && (
            <div className="campaign-controls-sidebar">
              <div className="manage-groups">
                <div className="manage-groups-title">
                  <h3>Image Groups</h3>
                  <h4>Selected: {picked.length}</h4>
                </div>

                <div className="manage-group-select">
                  <select
                    data-testid="group-selected"
                    defaultValue={addToGroupTarget || null}
                    name="addToGroupTarget"
                    // value={this.state.addToGroupTarget}
                    onChange={(e) => {
                      setAddToGroupTarget(e.target.value);
                    }}
                  >
                    <option value="null" disabled>
                      Select One
                    </option>
                    {campaignGroups.map((group, i) => {
                      if (group.name === 'Ungrouped') return null;
                      return (
                        <option key={i} value={group.name}>
                          {group.name}
                        </option>
                      );
                    })}
                  </select>

                  <button
                    className="secondary-button"
                    data-testid="group-addImages"
                    onClick={addToGroup}
                  >
                    Add selected
                  </button>
                  <button
                    className="secondary-button"
                    data-testid="group-removeImages"
                    onClick={removeFromGroup}
                  >
                    Remove selected
                  </button>
                </div>

                {batchId && toProcess === 0 && (
                  <>
                    <label style={{ display: 'block', marginTop: '30px' }}>
                      Number/Percent of images to select based on A.I. score
                    </label>
                    <div
                      className="manage-group-select"
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginTop: '3px',
                      }}
                    >
                      <input
                        required
                        value={selectImagesAmount}
                        onChange={(e) => {
                          if (e.target.value > 0) {
                            setSelectImagesAmount(e.target.value);
                          }
                          if (e.target.value === 0) {
                            setSelectImagesAmount('');
                          }
                        }}
                        style={{
                          width: '180px',
                          lineHeight: '16px',
                          padding: '0 10px',
                          border: '1px solid #e0e0e0',
                          borderRadius: '5px',
                          background: '#fff',
                        }}
                      />
                      <select
                        value={selectImagesType}
                        onChange={(e) => setSelectImagesType(e.target.value)}
                      >
                        <option defaultValue="fixed">Fixed</option>
                        <option value="percent">Percent</option>
                      </select>
                      <button
                        onClick={selectImages}
                        style={{ padding: '8px 15px' }}
                        className="secondary-button"
                        data-testid="group-addImages"
                      >
                        Select
                      </button>
                    </div>
                  </>
                )}

                <div
                  style={{
                    fontSize: '14px',
                    marginTop: '30px',
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <span>Contrast Accessible Threshold</span>
                  <a
                    style={{
                      border: 'none',
                      padding: 0,
                      display: 'inline',
                      textDecoration: 'underline',
                    }}
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://usecontrast.com/guide"
                  >
                    {'AA > 4.5 AAA > 7.0'}
                  </a>
                </div>
                <InputRange
                  maxValue={20}
                  minValue={0}
                  value={contrast}
                  onChange={(value) => setContrast(value)}
                />

                <div
                  style={{
                    marginTop: '40px',
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <button
                    className="secondary-button"
                    onClick={selectAllHandler}
                    data-testid="selectAllImages"
                  >
                    {allSelected ? 'Select none' : 'Select All Images'}
                  </button>
                  <button
                    className="secondary-button"
                    onClick={selectContrast}
                    data-testid="contrast-button"
                  >
                    Select contrast accessible
                  </button>
                </div>

                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginTop: '40px',
                  }}
                >
                  <button
                    type="button"
                    className="confirm-button"
                    onClick={openManageGroupsModal}
                    style={{ padding: '7px 12px' }}
                  >
                    Manage Groups
                  </button>
                  <button
                    style={{ color: '#52bad5', borderColor: '#52bad5' }}
                    className="secondary-button"
                    onClick={sendToArchive}
                    data-testid="download-archive"
                  >
                    Download selected
                  </button>
                </div>
              </div>

              <div className="manage-embed">
                <div className="manage-groups-title">
                  <h3>LP Integration</h3>
                </div>
                {activeCampaign.projectID && (
                  <div>
                    <button
                      type="submit"
                      className="confirm-button"
                      onClick={getCode}
                    >
                      Get the script
                    </button>
                    {displayCode && (
                      <div className="codeContainer">
                        <span className="elementName"></span>
                        <textarea
                          value={`<script>${displayCode}</script>`}
                          className="code"
                          id={'textareaScript'}
                          onClick={copyScript}
                        />
                      </div>
                    )}
                  </div>
                )}
              </div>

              <div className="manage-embed">
                <div className="manage-groups-title">
                  <h3>Personalization</h3>
                </div>
                {activeCampaign.projectID && (
                  <Link
                    className="confirm-button"
                    to={`/generateScript/${match.params.id}`}
                  >
                    Set Personalization Rules
                  </Link>
                )}
              </div>
            </div>
          )}
        </CampaignPreviewStyles>

        {UploadBannerArchiveModal}
        {ManageGroups}
        {MixBanners}
        {SendToFacebook}
      </DefaultLayout>
    );
  }

  const openCampaignModal = (campaign, isGroup = false) => {
    setIsCampaignGroup(isGroup);

    setSelectedCampaign(campaign);
    setModalType('campaign-modal');
    handleShowModal();
  };

  const openShareModal = (campaign) => {
    setSelectedCampaign(campaign);
    setModalType('share-modal');
    handleShowModal();
  };
  const openDeleteModal = (campaign) => {
    setSelectedCampaign(campaign);
    setModalType('delete-campaign');
    handleShowModal();
  };
  const openNotesModal = () => { };

  const updateCampaign = (updatedCampaign) => {
    let newCampaigns = [...subCampaigns];
    let found = false;

    newCampaigns = newCampaigns.map((current) => {
      if (current._id === updatedCampaign._id) {
        current = updatedCampaign;
        found = true;
      }
      return current;
    });
    if (!found) {
      newCampaigns.unshift(updatedCampaign);
    }

    setSubCampaigns(newCampaigns);
    setSelectedCampaign(null);
  };

  const deleteCampaign = (ev) => {
    const campaign = { ...selectedCampaign };
    fetchInstance('/campaign', {
      method: 'delete',
      body: JSON.stringify({ campaign }),
    })
      .then((res) => {
        if (res.ok) return res.json();
        throw new Error(res.status + ' ' + res.statusText);
      })
      .then((data) => {
        if (data.error) throw new Error(data.message);

        let oldCampaigns = [...subCampaigns];
        oldCampaigns = oldCampaigns.filter((current) => {
          if (current._id === campaign._id) return false;
          return current;
        });

        handleCloseModal();

        setSubCampaigns(oldCampaigns);
        setSelectedCampaign(null);
      })
      .catch((err) => setBanner(true, 'bad', err.message, false));
  };

  let editModal;
  if (showModal && modalType === 'campaign-modal') {
    editModal = (
      <CampaignDetails
        extractCSRF={extractCSRF}
        selectedCampaign={selectedCampaign}
        handleCloseModal={handleCloseModal}
        updateCampaign={updateCampaign}
        setBanner={setBanner}
        showModal={showModal}
        clientId={activeCampaign.client._id}
        userId={user._id}
        parentCampaign={activeCampaign}
        isCampaignGroup={isCampaignGroup}
      />
    );
  }

  let shareModal;
  if (showModal && modalType === 'share-modal') {
    shareModal = (
      <ShareCampaign
        extractCSRF={extractCSRF}
        selectedCampaign={selectedCampaign}
        handleCloseModal={handleCloseModal}
        showModal={showModal}
        setBanner={setBanner}
        updateCampaign={updateCampaign}
      />
    );
  }

  let DeleteModalUx;
  if (showModal && modalType === 'delete-campaign') {
    DeleteModalUx = (
      <DeleteModal
        acceptFunction={deleteCampaign}
        showModal={showModal}
        message={[
          'Do you really want to delete ',
          <b>{selectedCampaign.name}</b>,
          '?',
        ]}
        handleCloseModal={handleCloseModal}
      />
    );
  }

  return (
    <DefaultLayout>
      <CampaignTitleSection
        activeCampaign={activeCampaign}
        parent={activeCampaign}
      />

      <CampaignSearchBar
        handleSearchCampaignInput={handleSearchCampaignInput}
        openCampaignModal={openCampaignModal}
        isCampaignGroup={activeCampaign.isCampaignGroup}
      />

      <div style={style}>
        {(subCampaigns.length > 0 &&
          subCampaigns.map((campaign) => (
            <CampaignCard
              key={campaign._id}
              dataId={campaign._id}
              campaign={campaign}
              openShareModal={openShareModal}
              openEditModal={openCampaignModal}
              openDeleteModal={openDeleteModal}
              openNotesModal={openNotesModal}
            />
          ))) || <h2>No campaigns found</h2>}
      </div>

      {DeleteModalUx}
      {editModal}
      {shareModal}
    </DefaultLayout>
  );
};

export default CampaignPreview;
