import React, { Component } from 'react';
import ClientRulesPageStyles from './styles/ClientRulesPageStyles';
import ClientContext from './ClientContext';
import fetchInstance from '../utils/fetchInstance';

export class ClientRulesPage extends Component {
  static contextType = ClientContext;
  constructor(props) {
    super(props);
    this.state = {
      tags: [],
      selectedTag: '',
      newRule: {},
    };
  }

  componentDidMount() {
    this.getRules();
  }
  saveRuleToServer = (e) => {
    e.preventDefault();
    const action = e.target.name;
    let rule = { ...this.state.newRule };
    if (action === 'addRule') {
      return this.context.addRule(rule);
    } else if (action === 'deleteRule') {
      rule = {
        key: e.target.dataset.rulekey,
        value: e.target.dataset.rulevalue,
      };
      return this.context.deleteRule(rule);
    }
  };

  getRules = () => {
    return fetchInstance('/getCombinationRulesAndDictionary', {
      method: 'POST',
    })
      .then((res) => {
        if (res.ok) return res.json();
        throw new Error(res.status + ' ' + res.statusText);
      })
      .then((data) => {
        if (data.error) throw new Error(data.message);
        return this.setState({ tags: data.brandGuidelinesTags });
      })
      .catch((err) => this.context.setBanner(true, 'bad', err.message));
  };
  handleInput = (ev) => {
    const [target, key] = ev.target.name.split('__');
    const targetObject = { ...this.state[target] };
    targetObject[key] = ev.target.value;
    return this.setState({ [target]: targetObject });
  };
  render() {
    const { activeClient } = this.context;
    let hasGuidelines = false;
    if (activeClient && activeClient.brandGuidelines) {
      hasGuidelines = !!Object.keys(activeClient.brandGuidelines).length;
    }
    return (
      <ClientRulesPageStyles>
        <h3>Brand Guidelines</h3>
        {activeClient && (
          <>
            <h4> Add </h4>
            <select
              name="newRule__key"
              defaultValue={'null'}
              onChange={this.handleInput}
              data-testid="rule-key"
            >
              <option value="null">Select One</option>
              {this.state.tags.map((tag) => (
                <option data-testid="option" key={tag} value={tag}>
                  {tag}
                </option>
              ))}
            </select>
            <textarea
              name="newRule__value"
              onChange={this.handleInput}
              data-testid="rule-value"
            />
            <button
              onClick={this.saveRuleToServer}
              name="addRule"
              data-testid="rule-add"
            >
              Add rule
            </button>
          </>
        )}

        {hasGuidelines && (
          <ul className="guidelines">
            <h4>Existing</h4>
            {activeClient.brandGuidelines.map(({ key, value }, i) => {
              return (
                <li className="line" key={i} data-testid="rule">
                  {key.toUpperCase()}:
                  <textarea name={key} value={value} readOnly />
                  <button
                    name="deleteRule"
                    data-rulekey={key}
                    data-rulevalue={value}
                    onClick={this.saveRuleToServer}
                  >
                    ✖
                  </button>
                </li>
              );
            })}
          </ul>
        )}
      </ClientRulesPageStyles>
    );
  }
}

export default ClientRulesPage;
