import { MdDelete, MdSettings, MdShare } from 'react-icons/md';
import React, { useContext, useState } from 'react';
import clientBg from '../../images/clientBg.jpg';
import formatDate from '../../utils/formatDate';
import { Link } from 'react-router-dom';

import {
  CardInfoWrapper,
  CardDescription,
  CardStyle,
  CardSettings,
  CardInfo,
} from '../styles/CardStyle';
import { CampaignCardStyle } from '../styles/CampaignCardStyle';
import { GeneralModalContext } from '../GeneralModal';

const CampaignCard = ({
  campaign,
  openShareModal,
  openEditModal,
  openDeleteModal,
  openNotesModal,
}) => {
  const [, setModalId] = useState('');
  const { handleShowModal } = useContext(GeneralModalContext);

  const readMore = async (e, id) => {
    setModalId(id);
    handleShowModal();
  };

  return (
    <CampaignCardStyle>
      <CardStyle className="card-style">
        <CardSettings img={clientBg}>
          <div
            onClick={() => openDeleteModal(campaign)}
            className="action-button button-delete"
          >
            <MdDelete />
          </div>
          <div
            onClick={() => openShareModal(campaign)}
            className="action-button button-delete"
          >
            <MdShare />
          </div>
          <div
            onClick={() => openEditModal(campaign)}
            className="action-button button-settings"
          >
            <MdSettings />
          </div>
        </CardSettings>

        <CardDescription className="card-description">
          <Link
            to={`/campaign/${campaign._id}`}
            style={{ display: 'block', marginTop: '-35px' }}
          >
            <h2 className="cardTitle">{campaign.name}</h2>
            <div className="cardDetails">
              <div>
                <b>Domain:</b> {campaign.domain}
              </div>

              <div>
                <b>Status:</b> {campaign.active ? 'Active' : 'Disabled'}
              </div>

              <div>
                <span className="cardDetails">
                  <b>Type:</b>{' '}
                  {campaign.isCampaignGroup ? 'Campaign group' : 'Campaign'}
                </span>
              </div>
              {campaign.isCampaignGroup && (
                <div>
                  <span className="cardDetails">
                    <b>Subcampaigns:</b> {campaign.children.length}
                  </span>
                </div>
              )}

              {campaign.startDate && (
                <div>
                  <b>Start date:</b> {formatDate(campaign.startDate)}
                </div>
              )}

              {campaign.endDate && (
                <div>
                  <b>End date:</b> {formatDate(campaign.endDate)}
                </div>
              )}

              {campaign.notes && (
                <div>
                  <span className="campaign-notes">
                    <b>Notes:</b> {campaign.notes}
                  </span>
                  {campaign.notes.length > 29 && (
                    <span
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        openNotesModal(campaign);
                      }}
                      className="campaign-read-more"
                    >
                      Read more
                    </span>
                  )}
                </div>
              )}
            </div>
          </Link>
        </CardDescription>

        <CardInfoWrapper>
          <CardInfo>
            {campaign.created_by && (
              <span>
                Created by: <b>{campaign.created_by.name}</b>
              </span>
            )}
            <p>
              Created at: <b>{formatDate(campaign.created)}</b>
            </p>
          </CardInfo>
        </CardInfoWrapper>
      </CardStyle>
    </CampaignCardStyle>
  );
};

export default CampaignCard;
