import React from 'react';
import ScriptPageStyles from './styles/ScriptPageStyles';
import Loader from './styles/Loader';
import ScriptWizard from './ScriptWizard';
import Grid from '@material-ui/core/Grid';
import ControlCameraIcon from '@material-ui/icons/ControlCamera';
import DesktopMacIcon from '@material-ui/icons/DesktopMac';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import Button from '@material-ui/core/Button';
import CodeIcon from '@material-ui/icons/Code';
import EditableSelect from './EditableSelect';
import fetchInstance from '../utils/fetchInstance';
import config from '../config';

class ScriptModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingCombi: true,
      combi: null,
      options: null,
      code: null,
      copied: false,
      projectDetails: {},
      resourcesUrl: null,
      lpUrl: null,
      activeElement: null,
      inputStates: {},
    };

    this.codeRef = React.createRef();
    this.previewRef = React.createRef();
    this.previewLpRef = React.createRef();
    this.campaignId = this.props.match.params.id;
  }

  extractElement = (selector) => {
    // Regular expressions to capture the last class name or ID in the string
    const classNameRegex = /\.([^.#\s]+)$/;
    const idRegex = /#([^.#\s]+)$/;

    const classMatch = classNameRegex.exec(selector);
    const idMatch = idRegex.exec(selector);

    const element = selector.split('.', 1);

    // Checking which one exists and returning the appropriate match
    if (classMatch) {
      return element[0] + ' ' + classMatch[1];
    } else if (idMatch) {
      return element[0] + ' ' + idMatch[1];
    }

    return null;
  };

  getFinalIframeUrl = async (url) => {
    const response = await fetchInstance(
      `/proxyCheckXFrameOptions?url=${url}`,
      {
        method: 'GET',
      },
    );
    const data = await response.json();

    if (data.XFrameOptions === 'ALLOW-FROM *') {
      return url;
    }

    return `${config.proxyApiRoute}/proxyContent?target=${url}`;
  };

  lPPreview = async (e) => {
    this.setState({ lpUrl: null });

    if (e.target.value) {
      const result = await this.getFinalIframeUrl(e.target.value);
      this.setState({ lpUrl: result });
    }
    return;
  };

  saveRules = () => {
    return fetchInstance(`/api/v1/saverules`, {
      method: 'post',
      body: JSON.stringify({
        projectID: this.state.projectDetails.projectId,
        mappingRules: this.state.options,
      }),
    })
      .then((res) => {
        if (!res.ok) {
          return this.props.setBanner(true, 'bad', res.statusText, false, 5000);
        }
        return res.json();
      })
      .then((data) => {
        if (data.success) {
          console.log(data);
        } else {
          let message = data.message;
          if (data.message.includes('ENOENT')) {
            message =
              'The project attached to this campaign could not be found';
          }
          this.props.setBanner(true, 'bad', message, false, 5000);
        }
      })
      .catch((err) => {
        let message = err.message;
        if (err.message.includes('ENOENT')) {
          message = 'The project attached to this campaign could not be found';
        }
        this.props.setBanner(true, 'bad', message, false, 5000);
      });
  };

  fetchCombinations = () => {
    return fetchInstance(`/api/v1/getsingle?cid=${this.campaignId}&cn=0`, {
      method: 'post',
    })
      .then((res) => {
        if (!res.ok) {
          return this.props.setBanner(true, 'bad', res.statusText, false, 5000);
        }
        return res.json();
      })
      .then((data) => {
        if (data.success) {
          const resourceUrl = `${config.proxyApiRoute}/files/${data.projectDetails.projectId}`;
          this.setState({
            loadingCombi: false,
            combi: data.combi,
            projectDetails: data.projectDetails,
            resourceUrl,
            options: data.mappingRules,
          });

          // Adjust the zoom level on window resize
          window.addEventListener('resize', () =>
            this.adjustIframeZoom(data.projectDetails.width),
          );
          this.adjustIframeZoom(data.projectDetails.width);

          return;
        } else {
          let message = data.message;
          if (data.message.includes('ENOENT')) {
            message =
              'The project attached to this campaign could not be found';
          }
          this.props.setBanner(true, 'bad', message, false, 5000);
          // return this.props.toggleScriptModal();
        }
      })
      .catch((err) => {
        let message = err.message;
        if (err.message.includes('ENOENT')) {
          message = 'The project attached to this campaign could not be found';
        }
        this.props.setBanner(true, 'bad', message, false, 5000);
        // this.props.toggleScriptModal();
      });
  };

  processXpath = (event) => {
    if (event.data.type === 'pickElement') {
      const xpath = event.data.value;
      const elementName = Object.keys(this.state.inputStates)[0];

      if (elementName) {
        // Save element
        this.processValue(elementName + '//equivalentTo//element', xpath);

        const currentOptions = this.state.options;
        currentOptions[elementName].equivalentTo.element = xpath;
        this.setState({ options: currentOptions });

        this.props.setBanner(
          true,
          'good',
          'Mapped element was updated!',
          false,
          3000,
        );
      }
    }

    return;
  };

  adjustIframeZoom(width) {
    const container = document.getElementById('iframe-container');
    const iframe = document.getElementById('previewBannerIframe');

    if (!iframe) {
      return;
    }

    const containerWidth = container.offsetWidth;
    const iframeWidth = parseInt(width);

    // Calculate the zoom level needed to fit the image within the container
    const zoomLevel = containerWidth / iframeWidth;

    // Apply the zoom level to the iframe
    iframe.style.transform = `scale(${zoomLevel})`;
    iframe.style.width = `${100 / zoomLevel}%`;
    iframe.style.height = `${100 / zoomLevel}%`;
  }

  componentDidMount() {
    window.addEventListener('message', this.processXpath, false);

    if (!this.campaignId) {
      return this.setState({ loadingCombi: false, combi: [] });
    }
    this.fetchCombinations();
  }

  pickElement = (name) => {
    if (
      this.state.inputStates &&
      this.state.inputStates[name] &&
      this.state.inputStates[name].display
    ) {
      this.setState({ inputStates: {} });
      return;
    }
    const currentElementValue =
      this.state.options[name] &&
      this.state.options[name].equivalentTo &&
      this.state.options[name].equivalentTo.element;

    //this.processValue()

    this.setState({
      inputStates: {
        [name]: {
          display: true,
          value: currentElementValue,
        },
      },
    });
  };

  optionsSaver = (e) => {
    let value;
    if (e.target.type === 'checkbox') {
      value = e.target.checked;
    } else {
      value = e.target.value;
    }

    this.processValue(e.target.name, value);
  };

  processValue = (name, value) => {
    let archiveElement, option, selector;
    try {
      [archiveElement, option, selector] = name.split('//');
    } catch (error) {
      return;
    }

    const stateOptions = { ...this.state.options } || {};

    stateOptions[archiveElement] = stateOptions[archiveElement] || {};
    const currentElement = stateOptions[archiveElement];
    currentElement[option] = currentElement[option] || {};

    if (selector) {
      if (typeof currentElement[option] !== 'object') {
        const temp = currentElement[option];
        currentElement[option] = {};
        currentElement[option].value = temp;
        currentElement[option][selector] = value;
      } else {
        currentElement[option][selector] = value;
      }
    } else {
      if (typeof currentElement[option] !== 'object') {
        currentElement[option] = value;
      } else {
        currentElement[option].value = value;
      }
    }

    this.setState({ options: stateOptions });
  };

  renderCombi() {
    const combi = { ...this.state.combi };
    const elements = Object.keys(combi);
    const initialOptionsForColor = ['color', 'fill', 'backgroundColor'];
    const initialOptionsForImage = ['src', 'source', 'backgroundImage'];
    let render = [];

    elements.forEach((element, i) => {
      if (element === 'meta') return;

      const options = Object.keys(combi[element]);
      let previewEl = null;
      let listFirstPart = [];
      let listSecondPart = [];
      const elementInState = this.state.options[element] || {};

      options.forEach((option, j) => {
        if (option === 'pickedColor') return;

        if (option === 'color' || option === 'backgroundColor') {
          return listFirstPart.push(
            <div className="optionGrouper" key={j}>
              <li>
                <span className="optionName">{option}</span>
                <input
                  id={`${element}//${option}`}
                  type="checkbox"
                  name={`${element}//${option}`}
                  checked={
                    elementInState[option]
                      ? elementInState[option].value
                      : false
                  }
                />
                <label htmlFor={`${element}//${option}`} />
              </li>
              <li
                style={{
                  display: elementInState[option]
                    ? elementInState[option].value
                      ? 'flex'
                      : 'none'
                    : 'none',
                }}
                className="smallerInputContainer editableSelectContainer"
              >
                <EditableSelect
                  name={`${element}//${option}//equivalentTo//`}
                  options={initialOptionsForColor}
                  onChange={this.processValue}
                  initialValue={
                    elementInState[option]
                      ? elementInState[option].equivalentTo
                      : 'color'
                  }
                ></EditableSelect>
              </li>
            </div>,
          );
        }

        if (
          option === 'src' ||
          option === 'source' ||
          option === 'backgroundImage'
        ) {
          previewEl = (
            <div className={'previewElementImage'}>
              <small>
                <i>Element image preview</i>
              </small>
              <img
                className="imgPreview"
                src={`${combi[element].src[0]}`}
              />
            </div>
          );

          return listFirstPart.push(
            <div className="optionGrouper" key={j}>
              <li>
                <span className="optionName">{option}</span>
                <input
                  id={`${element}//${option}`}
                  type="checkbox"
                  name={`${element}//${option}`}
                  checked={
                    elementInState[option]
                      ? elementInState[option].value
                      : false
                  }
                />
                <label htmlFor={`${element}//${option}`} />
              </li>
              <li
                style={{
                  display: elementInState[option]
                    ? elementInState[option].value
                      ? 'flex'
                      : 'none'
                    : 'none',
                }}
                className="smallerInputContainer editableSelectContainer"
              >
                <EditableSelect
                  name={`${element}//${option}//equivalentTo//`}
                  options={initialOptionsForImage}
                  onChange={this.processValue}
                  initialValue={
                    elementInState[option]
                      ? elementInState[option].equivalentTo
                      : 'src'
                  }
                ></EditableSelect>
              </li>
            </div>,
          );
        } else {
          return listSecondPart.push(
            <li key={j}>
              <span className="optionName">{option}</span>
              <input
                id={`${element}//${option}`}
                type="checkbox"
                name={`${element}//${option}`}
                checked={
                  elementInState[option] ? elementInState[option].value : false
                }
              />
              <label htmlFor={`${element}//${option}`} />
            </li>,
          );
        }
      });

      return render.push(
        <div
          className="elementContainer"
          key={i}
          id={element}
          onChange={this.optionsSaver}
        >
          <div className={'inputContainer'}>
            <label>
              1. What element should map "{this.extractElement(element)}"?
            </label>
            <div className={'pickElementContainer'}>
              <input
                disabled={!this.state.inputStates[element]}
                type="text"
                name={`${element}//equivalentTo//element`}
                className={'elementPicker'}
                placeholder="CSS selector"
                value={
                  elementInState.equivalentTo
                    ? elementInState.equivalentTo.element
                    : ''
                }
              />
              <Button
                onClick={() => {
                  this.pickElement(element);
                }}
                fontSize="large"
                color="primary"
                className={'pickElementBtn'}
                startIcon={<ControlCameraIcon />}
              ></Button>
            </div>
          </div>

          <div className={'inputContainer'}>
            <label>
              2. Select properties from "{this.extractElement(element)}" to
              import into the landing page:
            </label>
            <ul className="elementList">
              {listFirstPart}
              {listSecondPart}
            </ul>
            {previewEl}
          </div>
        </div>,
      );
    });

    return render;
  }

  generateCode = () => {
    /*
        // send options from state to server
        if (!Object.keys(this.state.options).length) return;
        const options = {...this.state.options};
        const xsrfToken = this.props.extractCSRF();
        fetch(config.apiUrl + "/generatecode", {
            headers: {
                "Content-type": "application/json",
                "xsrf-token": xsrfToken
            },
            method: "post",
            body: JSON.stringify({options, cid: this.campaignId})
        })
            .then(res => {
                if (!res.ok)
                    return this.props.setBanner(true, "bad", res.statusText, false, 5000);

                return res.json();
            })
            .then(data => {
                // get back uglified code
                if (data.success) {
                    this.setState({code: data.code});
                } else if (data.error) {
                    console.log(data.message);
                }
            })
            .catch(err => console.log(err.message));
         */

    this.saveRules();
  };

  copyCode = (ev) => {
    const node = this.codeRef.current;
    node.select();
    document.execCommand('copy');
    this.setState({ copied: true });
  };

  showMobilePreview = () => {
    this.previewLpRef.current.style.width = '480px';
  };

  showDesktopPreview = () => {
    this.previewLpRef.current.style.width = '100%';
  };

  render() {
    return (
      <ScriptPageStyles>
        <div className={'titleAndSubtitle'}>
          <h1>Map banner elements</h1>
          <p>
            For each element pick the settings you want the script to change on
            your page.
          </p>
        </div>
        <Grid container spacing={5}>
          <Grid item xs={12} sm={12} md={4}>
            {this.state.loadingCombi ? <Loader /> : ''}
            {this.state.combi && (
              <ScriptWizard formItems={this.renderCombi()}></ScriptWizard>
            )}
            <div>
              <button
                type="submit"
                className="generateCodeButton"
                onClick={this.generateCode}
                disabled={!this.state.options}
              >
                <CodeIcon fontSize="large"></CodeIcon> Save rules
              </button>
              {this.state.code && (
                <div className="codeContainer">
                  <span className="elementName">
                    Code -{' '}
                    {this.state.copied
                      ? 'copied to clipboard'
                      : 'click to copy'}
                  </span>
                  <textarea
                    value={`<script>${this.state.code}</script>`}
                    className="code"
                    ref={this.codeRef}
                    onClick={this.copyCode}
                  />
                </div>
              )}
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={8}>
            <div className="preview">
              <div id={'iframe-container'}>
                {this.state.loadingCombi ? <Loader /> : ''}
                {this.state.combi && (
                  <iframe
                    src={`${this.state.resourceUrl}/index.html`}
                    ref={this.previewRef}
                    id={'previewBannerIframe'}
                  />
                )}
              </div>
            </div>
          </Grid>
        </Grid>
        <div>
          <div className={'titleAndSubtitle'}>
            <h1>Landing page preview</h1>
            <p>
              Please note that preview will be available only if the script is
              installed on LP.
            </p>
          </div>
          <div>
            <label>Type here your LP url:</label>
            <input
              type={'text'}
              id={'lpurl'}
              onChange={this.lPPreview}
              placeholder={'Ex: https://yourlandingpage.com/campaign'}
            />
          </div>
          {this.state.lpUrl && (
            <div>
              <div className={'iframeButtons'}>
                <Button
                  size="small"
                  color="primary"
                  startIcon={<DesktopMacIcon />}
                  onClick={this.showDesktopPreview}
                >
                  Desktop
                </Button>
                <Button
                  size="small"
                  color="primary"
                  startIcon={<PhoneIphoneIcon />}
                  onClick={this.showMobilePreview}
                >
                  Mobile
                </Button>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignContent: 'center',
                }}
              >
                <iframe
                  className="previewLP"
                  src={this.state.lpUrl}
                  ref={this.previewLpRef}
                />
              </div>
            </div>
          )}
          {!this.state.lpUrl && (
            <div className={'titleAndSubtitle noAvailablePreview'}>
              <p>
                <i>No available LP url.</i>
              </p>
            </div>
          )}
        </div>
      </ScriptPageStyles>
    );
  }
}

export default ScriptModal;
