import React, { useState } from 'react';
import FormStyles from '../styles/FormStyles';
import Title from '../styles/TitleStyle';
import { MdClose } from 'react-icons/md';
import GeneralModalStyles from '../styles/GeneralModalStyles';
import fetchInstance from '../../utils/fetchInstance';

const ShareCampaign = ({
  selectedCampaign,
  handleCloseModal,
  updateCampaign,
  showModal,
  setBanner,
  extractCSRF,
}) => {
  const [currentCampaign, setCurrentCampaign] = useState(selectedCampaign);

  const handlePreviewInputChange = (ev) => {
    const newCampaign = { ...currentCampaign };

    const changedProperty = ev.currentTarget.name;
    let newValue = ev.currentTarget.value;

    if (ev.currentTarget.type === 'checkbox') {
      newValue = ev.currentTarget.checked;
    }

    if (changedProperty === 'publicLink__expires') {
      if (!currentCampaign.publicLink) currentCampaign.publicLink = {};
      currentCampaign.publicLink.expires = newValue;
    } else {
      currentCampaign[changedProperty] = newValue;
    }

    newCampaign[changedProperty] = newValue;

    setCurrentCampaign(newCampaign);
  };

  const togglePublicLink = () => {
    // this.setState({ previewBusy: true });
    let campaign = { ...currentCampaign };

    if (
      campaign.publicLink &&
      campaign.publicLink.link &&
      !window.confirm(`Are you sure you want to destroy public link?`)
    )
      return;
    // fetch and post to API
    return fetchInstance('/togglePublicLink', {
      method: 'POST',
      body: JSON.stringify({
        cid: campaign._id,
        delete: !!(campaign.publicLink && campaign.publicLink.link),
        expires: campaign.publicLink ? campaign.publicLink.expires : null,
      }),
    })
      .then((res) => {
        if (!res.ok) throw new Error(res.statusText);
        return res.json();
      })
      .then((data) => {
        if (data.success) return data.campaign;
        if (data.error) throw new Error(data.message);
      })
      .then((updatedCampaign) => {
        updateCampaign(updatedCampaign);
        setCurrentCampaign(updatedCampaign);
      })
      .catch((err) => {
        // setState({ previewBusy: false };
        setBanner(true, 'bad', err.message, false);
      });
  };

  const saveCampaign = (ev) => {
    ev.stopPropagation();
    ev.preventDefault();

    let campaign = { ...currentCampaign };

    new Promise((resolve, reject) => {
      if (!campaign._id) {
        resolve(
          fetchInstance('/campaign', {
            method: 'post',
            body: JSON.stringify({ campaign: campaign }),
          }),
        );
      } else {
        resolve(
          fetchInstance('/campaign', {
            method: 'put',
            body: JSON.stringify({ campaign: campaign }),
          }),
        );
      }
    })
      .then((res) => {
        if (!res.ok) throw new Error(res.statusText);
        return res.json();
      })
      .then((data) => {
        if (data.success) return data.campaign;
        if (data.error) throw new Error(data.message);
      })
      .then((updatedCampaign) => {
        handleCloseModal();
        updateCampaign(updatedCampaign);

        setBanner(true, 'good', 'Sharing updated successfully');
      })
      .catch((err) => {
        // this.setState({ previewBusy: false };
        setBanner(true, 'bad', err.message, false);
      });
  };

  return (
    <GeneralModalStyles
      className="modal-wrapper"
      style={{ opacity: showModal ? 1 : 0 }}
    >
      <div className="modal-body">
        <div onClick={handleCloseModal} className="modal-close">
          <MdClose className="action-button button-delete" />
        </div>

        <FormStyles onSubmit={saveCampaign}>
          <Title>Sharing</Title>

          <div className="group">
            <label>Public Link</label>
            <div style={{ display: 'flex' }}>
              <input
                readOnly
                value={
                  currentCampaign &&
                  currentCampaign.publicLink &&
                  currentCampaign.publicLink.link
                    ? `${
                        window.location.protocol +
                        '//' +
                        window.location.hostname +
                        (window.location.port ? ':' + window.location.port : '')
                      }/campaign/${currentCampaign.publicLink.link}/shared`
                    : 'Not public'
                }
                onClick={() => {
                  navigator.clipboard.writeText(
                    `${
                      window.location.protocol +
                      '//' +
                      window.location.hostname +
                      (window.location.port ? ':' + window.location.port : '')
                    }/campaign/${currentCampaign.publicLink.link}/shared`,
                  );
                  setBanner(true, 'good', 'Link copied to clipboard');
                }}
              />
              <button
                onClick={togglePublicLink}
                type="button"
                className={
                  currentCampaign &&
                  currentCampaign.publicLink &&
                  currentCampaign.publicLink.link
                    ? 'warning-button'
                    : 'confirm-button'
                }
                style={{ marginLeft: '10px', padding: '1rem' }}
              >
                {currentCampaign &&
                currentCampaign.publicLink &&
                currentCampaign.publicLink.link
                  ? 'Destroy'
                  : 'Generate'}
              </button>
            </div>
          </div>

          <div className="group">
            <label>Expire Date</label>
            <input
              name="publicLink__expires"
              type="date"
              value={
                currentCampaign.publicLink && currentCampaign.publicLink.expires
                  ? new Date(currentCampaign.publicLink.expires)
                      .toISOString()
                      .substr(0, 10)
                  : ''
              }
              onChange={handlePreviewInputChange}
            />
          </div>

          <div style={{ display: 'flex', alignItems: 'center' }}>
            <label>Status</label>
            {/* {this.props.activeCampaign.active ? "Active" : "Disabled"} */}
            <div style={{ marginLeft: '1rem' }}>
              <input
                id="singleToggle"
                name="active"
                type="checkbox"
                checked={currentCampaign.active ? true : false}
                onChange={handlePreviewInputChange}
              />
              <label htmlFor="singleToggle" />
            </div>
          </div>

          <div className="button-wrapper">
            <button className="confirm-button">Save</button>
          </div>
        </FormStyles>
      </div>
    </GeneralModalStyles>
  );
};

export default ShareCampaign;
