import { createGlobalStyle } from 'styled-components';

const globalTheme = {
  green: '#6dd92bdb',
  black: '#393939',
  grey: '#3A3A3A',
  lightGrey: ' #E1E1E1',
  lightBlue: '#52bad5',
  lightBlueHover: '#349cb7',
  facebookBlue: '#4267b2',
  boxShadow: '0 1px 3px 0 #393939',

  // newest
  red: '#e64c0d',
  blue: 'rgb(82, 186, 213)',
  borderColor: '#e0e0e0',
  borderRadius: '5px',
  border: '1px solid #e0e0e0',
};

const GlobalStyles = createGlobalStyle`
    html{
        box-sizing: border-box;
        font-size: 9px;
    }
    
    *, *:before, *:after {
        box-sizing: inherit;
    }
    
    body{
        padding: 0;
        margin: 0;
        font-size: 1.5rem;
        line-height: 2;
        font-family: "Open Sans";
        background-color: #f8f8f8;
    }
    
    a{
        text-decoration: none;
        color: ${globalTheme.black};
    }
    
    .confirm-button {
        display: inline-block;
        line-height:20px;
        color: white;
        padding: 1rem 2rem;
        border-radius: ${globalTheme.borderRadius};
        background: ${globalTheme.blue};
        // font-size: 14px;
        font-size: 16px;
        letter-spacing: 0.1px
        // text-transform: uppercase;
        border: none;
        cursor: pointer;
        transition: 0.3s;
        &:hover{
            background: rgb(93 213 244);
        }
        &:hover,
        &:active,
        &:focus {
            border: none;
            outline: none;
            
        }
    }
    
    .inactive-button{
        color: #cecece;
        padding: 1rem 2rem;
        border-radius: ${globalTheme.borderRadius};
        font-size: 14px;
        text-transform: uppercase;
        border: 1px solid #cecece;
        background: #fff;
        cursor: pointer;
        &:hover,
        &:active,
        &:focus {
            border: 1px solid #000;
            outline: none;
            color:#000;
            transition: all .2s;
        }
    }
    
    .warning-button {
        color: white;
        padding: 1rem 3rem;
        border-radius: ${globalTheme.borderRadius};
        font-size: 14px;
        text-transform: uppercase;
        border: none;
        background: ${globalTheme.red};
        cursor: pointer;
        &:hover,
        &:active,
        &:focus {
            border: none;
            outline: none;
        }
    }
    
    .action-button {
        display: inline-flex;
        background: #fff;
        align-items: center;
        border-radius: ${globalTheme.borderRadius};
        font-size: 2rem;
        outline: none;
        border: 1px solid #e0e0e0;
        padding: 0.5rem 1rem;
        cursor: pointer;
        margin-left: 1rem;
        
        svg {
            transition: transform 0.3s;
            font-size: 2rem;
            color: #000;
        }
    
        &:hover svg {
            transform: rotate(40deg);
        }
        
        &:hover.button-settings svg {
            color: ${globalTheme.blue};
        }
            
        &:hover.button-settings {
            border-color: ${globalTheme.blue};
        }
        
        &:hover.button-delete svg {
            color: ${globalTheme.red};
        }
        
        &:hover.button-delete {
            border-color: ${globalTheme.red};
        }
    }
    
    .secondary-button{
        line-height: 16px;
        background: #fff;
        padding: 8px 10px;
        color: #000;
        border-color: black;
        text-transform: capitalize;
        display: inline-flex;
        border-radius: ${globalTheme.borderRadius};
        border: 1px solid #e0e0e0;
        cursor: pointer;
        transition: transform 0.3s;
        
        &:focus {
            outline: none;
        }
        
        &:hover {
            border-color: ${globalTheme.blue};
            color: ${globalTheme.blue};
        }
    }
`;

export default GlobalStyles;
export { globalTheme };
