import styled from "styled-components";

export default styled.form`
  max-width: 1600px;
  margin: auto;
  font-family: "Open Sans";
  font-size: 1.7rem;
  line-height: 1.5;
  font-weight: 600;
  color: ${props => props.theme.black};
  padding: 1rem 1rem;
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: 20px;
  justify-items: center;


  .chapter {
    padding: 1rem 0;
    display: grid;
    align-items: stretch;
    align-content:flex-start;
    grid-gap: 7px

  }
  .buttonGrouping {
    display: flex;
  }
  h3 {
    align-self: start;
    border-bottom: solid 2px ${props => props.theme.black};

  }

  .main,
  .tools,
  .save {
    width: 100%;
    display: grid;
  }

  .save {
    grid-column: 1/ 3;
    @media (max-width: 700px) {
      grid-column: 1/2;
    }
  }


  fieldset {
    border: none;
    background: white;
    padding: 1rem 1rem;
  }

  input[type="checkbox"] {
    display: none;
    & + label {
      width: 66px;
      height: 2.3rem;
      cursor: pointer;
      border: solid 2px ${props => props.theme.lightGrey};
      display: block;
      position: relative;
      transform: skew(-7deg);
      &:before {
        content: "";
        position: absolute;
        border: solid 2px white;
        left: 0;
        width: 32px;
        height: calc(2.3rem - 4px);
        background: ${props => props.theme.lightGrey};
        transition: all 0.2s ease-out;
      }
    }
  }

  input[type="checkbox"]:checked {
    & + label {
      &:before {
        background: ${props => props.theme.lightBlue};
        left: 30px;
      }
    }
  }

  input[type="date"] {
    border: solid 2px ${props => props.theme.lightGrey};
  }
  input,
  textarea,
  select {
    padding: 1rem 1rem;
    font-size: 1em;
    font-family: "Open Sans";
    border: none;
    width: 100%;
    margin-top: 5px;
    &:focus {
      outline: 0;
    }
  }


  textarea {
    border: solid 2px ${props => props.theme.lightGrey};
    &:active,
    &:focus {
      outline: solid 2px ${props => props.theme.lightBlue};
    }
  }
  select {
    border: solid 2px ${props => props.theme.lightGrey};
  }
  a,
  button {
    font-family: "Open Sans";
    font-size: inherit;
    font-weight: 600;
    text-transform: uppercase;
    background: white;
    cursor: pointer;
    box-shadow: none;
    padding: 1rem 2rem;
    border: none;
    color: ${props => props.theme.black};
    border: solid 2px ${props => props.theme.lightGrey};
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 1rem;
    transition: all 0.2s ease-out;
    &:hover,
    &:active {
      /* color: white; */
      background: ${props => props.theme.lightGrey};
      border: solid 2px white;
    }
  }

  button[type="submit"],
  input[type="submit"] {
    text-transform: uppercase;
    cursor: pointer;
    color: white;
    display: flex;
    justify-content: center;
    &:disabled {
      background: ${props => props.theme.lightGrey};
    }
    &:enabled {
      background: ${props => props.theme.lightBlue};
    }
    &:hover,
    &:active {
      background: ${props => props.theme.lightBlue};
      &:disabled {
        background: ${props => props.theme.lightGrey};
      }
    }
  }

  button.unlink {
    width: max-content;
    border: none;
    font-size: 1.5em;
    border: solid 2px ${props => props.theme.lightGrey};
    &:hover {
      background: ${props => props.theme.red};
      color: white;
    }
  }
  .dangerButton:hover,
  .dangerButton:active {
    color: white;
    background: ${props => props.theme.red};
  }
  .group {
    position: relative;
    width: 100%;
    border-bottom: solid 2px ${props => props.theme.lightGrey};
    margin-bottom: 2rem;
    label {
      position: absolute;
      top: 0;
      left: 1rem;
      font-size: 1.2em;
      transition: all 0.2s ease-out;
    }
    span: {
      position: relative;
      width: 100%;
      display: block;
    }
    span:before {
      content: "";
      position: absolute;
      bottom: -2px;
      left: 50%;
      height: 3px;
      background: ${props => props.theme.lightBlue};
      transition: all 0.2s ease-out;
      width: 0;
    }
    span:after {
      content: "";
      position: absolute;
      bottom: -2px;
      right: 50%;
      height: 3px;
      background: ${props => props.theme.lightBlue};
      transition: all 0.2s ease-out;
      width: 0;
    }
    input:focus ~ label,
    input:valid ~ label {
      top: -1rem;
      font-size: 1em;
    }
    input:focus ~ span:before {
      width: 50%;
    }
    input:focus ~ span:after {
      width: 50%;
    }
  }

  div.facebookConnect {
    background: ${props => props.theme.facebookBlue};
    color: white;
    border: none;
  }
  img.flogo,
  img.glogo {
    width: 1em;
    margin: 0 7px;
  }

  /* ARCHIVE DROP MODAL */
  .archiveSelectModal {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }

  #browseFilesLink {
    color: rgba(72, 72, 72, 0.472);
    font-size: 1.2em;
    cursor: pointer;
  }

  #browseFilesLink:hover {
    color: rgb(72, 72, 72);
    text-decoration: underline;
  }
  #archive {
    display: none;
  }

  .showing {
    visibility: visible !important;
  }

  .archivePopup {
    background: white;
    min-width: 200px;
    width: 45%;
    max-width: 475px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .archiveDrop {
    box-sizing: border-box;
    z-index: 10;
    padding: 25px;
    display: flex;
    width: 100%;
    justify-items: center

    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    border-bottom: solid 1px rgba(72, 72, 72, 0.467);
    transition: opacity 0.2s ease-in-out 0s;
  }

  #archiveDropInstructions {
    align-self:center;
  }
  .archiveDropDragging {
    opacity: 0.2;
  }

  #svgWrapper {
    max-width: 70%;
    max-height: 70%;
  }

  .confirmArchiveButtonWrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .confirmArchiveButton {
    display: flex;
    justify-content: center;
  }

  #browseFilesLink {
    padding: 0;
    margin: 0;
  }

  @media(max-width: 1300px){
    font-size: 1.5rem;

  }
  @media (max-width: 700px) {

    padding: 0;
    border: none;
    grid-template-columns: 1fr;

  }

`;
