import React, { useEffect, useState } from 'react';
import {
  CardDescription,
  CardInfo,
  CardInfoWrapper,
  CardSettings,
  CardStyle,
} from './styles/CardStyle';
import clientBg from '../images/clientBg.jpg';
import formatDate from '../utils/formatDate';
import Title from './styles/TitleStyle';
import DefaultLayout from './DefaultLayout';
import FormStyles from './styles/FormStyles';
import fetchInstance from '../utils/fetchInstance';

const style = {
  display: 'flex',
  flexWrap: 'wrap',
  overflowY: 'auto',
};

const ManageUsers = ({ setBanner, extractCSRF }) => {
  const [users, setUsers] = useState([]);

  const roles = {
    200: 'User',
    500: 'Admin',
    501: 'SuperAdmin',
  };

  useEffect(() => {
    fetchUsers().then((users) => {
      setUsers(users);
    });
  }, []);

  const fetchUsers = async () => {
    try {
      const res = await fetchInstance('/getusers', {
        method: 'POST',
      });

      if (!res.ok) {
        throw new Error(res.status + ' ' + res.statusText);
      }
      const data = await res.json();

      if (data.error) {
        throw new Error(data.message);
      }

      return data;
    } catch (err) {
      // setState({ loading: false });
      setBanner(true, 'bad', err.message);
    }
  };

  const updatePermissions = ({ currentTarget: input }) => {
    const [user] = users.filter((user) => user._id === input.id);

    if (input.name === 'status') {
      user.approved = !user.approved;
    }

    if (input.name === 'permissions') {
      user.permission = input.value;
    }

    return fetchInstance('/updateApprovalAndPerms', {
      method: 'POST',
      body: JSON.stringify(user),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        throw new Error(res.status + ' ' + res.statusText);
      })
      .then((data) => {
        if (data.success) {
          return setBanner(true, 'good', 'Changes saved successfully');
        }

        throw new Error(data.message);
      })
      .catch((err) => setBanner(true, 'bad', err.message));
  };

  return (
    <DefaultLayout>
      <Title>Manage users</Title>

      {users.length !== 0 && (
        <FormStyles style={style}>
          {users.map((user) => (
            <CardStyle key={user._id}>
              <CardSettings img={clientBg}>
                {/*<div onClick={() => openDeleteModal(client)} className="action-button button-delete">*/}
                {/*    <MdDelete />*/}
                {/*</div>*/}

                {/*<div onClick={() => openEditModal(client)} className="action-button button-settings">*/}
                {/*    <MdSettings />*/}
                {/*</div>*/}
              </CardSettings>

              <CardDescription
                style={{
                  height: '190px',
                  color: '#000',
                  textAlign: 'left',
                  padding: '2rem',
                  paddingTop: '45px',
                  position: 'relative',
                }}
              >
                <h2
                  style={{
                    maxWidth: '220px',
                    lineHeight: '30px',
                    textAlign: 'center',
                    color: '#000',
                    position: 'absolute',
                    transform: 'translate(-50%, -50%)',
                    background: 'rgba(255,255,255,0.8)',
                    top: '0',
                    left: '50%',
                    padding: '8px',
                    borderRadius: '15px',
                    border: '1px solid #e0e0e0',
                    margin: '0',
                    wordBreak: 'break-word',
                  }}
                >
                  {user.name}
                </h2>
                <div style={{ marginTop: '6px' }} data-testid="user-email">
                  Email: {user.email}
                </div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginTop: '10px',
                  }}
                >
                  Approved:
                  <div style={{ marginLeft: '1rem' }}>
                    <input
                      name="status"
                      id={user._id}
                      type="checkbox"
                      checked={user.approved}
                      onChange={updatePermissions}
                    />
                    <label htmlFor={user._id} />
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginTop: '10px',
                    height: '27px',
                  }}
                >
                  Permission:
                  <select
                    style={{
                      width: '100px',
                      height: '30px',
                      marginLeft: '10px',
                    }}
                    name="permissions"
                    id={user._id}
                    onChange={updatePermissions}
                    defaultValue={user.permission}
                  >
                    {Object.entries(roles).map(([value, role]) => (
                      <option data-testid="option" key={value} value={value}>
                        {role}
                      </option>
                    ))}
                  </select>
                </div>
              </CardDescription>

              <CardInfoWrapper>
                <CardInfo>
                  <p></p>
                  <p>
                    Created at: <b>{formatDate(user.created)}</b>
                  </p>
                </CardInfo>
              </CardInfoWrapper>
            </CardStyle>
          ))}
        </FormStyles>
      )}
    </DefaultLayout>
  );
};

export default ManageUsers;
