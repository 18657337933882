import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom'
import PreviewStyles from './styles/PreviewStyles'
import config from '../config'

export default class Preview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  // handleResponse = data => {
  //   const xsrfToken = this.props.extractCSRF();
  //   fetch(config.apiUrl + "/incomingFacebook", {
  //     method: "post",
  //     body: JSON.stringify({
  //       fbStuff: data,
  //       cid: this.props.activeCampaign._id
  //     }),
  //     headers: {
  //       "Content-Type": "application/json",
  //       "xsrf-token": xsrfToken
  //     }
  //   })
  //     .then(res => {
  //       if (!res.ok) throw new Error(res.statusText);
  //       return res.json();
  //     })
  //     .then(data => {
  //       if (data.error) throw new Error(data.message);
  //       return this.props.updateActiveCampaign(data);
  //     })
  //     .catch(err =>
  //       this.props.setBanner(true, "bad", err.message, false, 5000)
  //     );
  // };

  handleError = (err) =>
    this.props.setBanner(true, 'bad', err.message, false, 5000);

  handleArchiveDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  showSpinner = () => (
    <div className="spinner active">
      <h3>Uploading...</h3>
    </div>
  );

  showArchiveDrop = () => (
    <React.Fragment>
      <div className="archiveDrop" onDrop={this.props.handleArchiveDrop}>
        <h3 id="archiveDropInstructions">Drop archive file here</h3>
        <img id="svgWrapper" src="/folder.svg" alt="folder icon" />
        <label id="browseFiles">
          <p id="browseFilesLink">Or browse your system</p>
          <input
            type="file"
            name="archive"
            id="archive"
            required
            accept="application/zip, application/octet-stream, application/x-zip-compressed, multipart/x-zip"
            onChange={this.props.handleArchiveInputChange}
          />
        </label>
        <p>
          Selected archive:{' '}
          {this.props.selectedArchive
            ? this.props.selectedArchive.name
            : 'None'}
        </p>
        <p>Overwrite existing files:</p>
        <div className="singleToggle">
          <input
            id="overwrite"
            name="overwrite"
            type="checkbox"
            onChange={this.props.toggleOverwrite}
            value={this.props.overwrite}
          />
          <label htmlFor="overwrite" />
        </div>
      </div>
      <div className="confirmArchiveButtonWrapper">
        <button
          type="submit"
          className={`confirmArchiveButton${this.props.selectedArchive ? '__enabled' : ''
            }`}
          disabled={!this.props.selectedArchive}
          onClick={this.props.uploadArchive}
        >
          Upload
        </button>
      </div>
    </React.Fragment>
  );
  uploadModal = () => (
    <div
      className="archiveSelectModal"
      onDragOver={this.handleArchiveDragOver}
      onClick={this.props.toggleUploadModal}
    >
      <div className="archivePopup">
        {this.props.uploadingFiles
          ? this.showSpinner()
          : this.showArchiveDrop()}
      </div>
    </div>
  )

  handleFacebookConnect = () => {
    const form = document.createElement('form');
    form.method = 'POST';
    form.action = `${config.apiFbUrl}/facebook-auth`

    const tokenInput = document.createElement('input');
    tokenInput.type = 'hidden';
    tokenInput.name = 'token';
    const token = localStorage.getItem('token');
    tokenInput.value = token

    form.appendChild(tokenInput);

    // Append the form to the body and submit it
    document.body.appendChild(form);

    form.submit();
  };


  handleGoogleConnect = () => {
    const form = document.createElement('form');
    form.method = 'POST';
    form.action = `${config.apiGoogleUrl}/googleAuth`

    const tokenInput = document.createElement('input');
    tokenInput.type = 'hidden';
    tokenInput.name = 'token';
    const token = localStorage.getItem('token');
    tokenInput.value = token

    form.appendChild(tokenInput);

    // Append the form to the body and submit it
    document.body.appendChild(form);

    form.submit();
  };


  render() {
    let campaignFormFields;
    if (this.props.activeCampaign)
      campaignFormFields = (
        <React.Fragment>
          <div className="main">
            <div className="chapter" data-testid="campaign-overview">
              <h3>Overview</h3>
              <div className="group">
                <input
                  className="formInput"
                  type="text"
                  name="name"
                  value={this.props.activeCampaign.name || ''}
                  onChange={this.props.handlePreviewInputChange}
                  required
                />
                <label>Name</label>
                <span />
              </div>
              <div className="group">
                <input
                  className="formInput"
                  type="text"
                  name="domain"
                  value={this.props.activeCampaign.domain || ''}
                  onChange={this.props.handlePreviewInputChange}
                  required
                />
                <label>Domain</label>
                <span />
              </div>
              <div>
                <label>
                  Client
                  <select
                    onChange={this.props.handlePreviewInputChange}
                    name="client"
                    defaultValue={
                      this.props.activeCampaign.client
                        ? this.props.activeCampaign.client._id ||
                        this.props.activeCampaign.client
                        : null
                    }
                  >
                    {/*<option value={null} key="nullvalue">*/}
                    {/*    Not selected{" "}*/}
                    {/*</option>*/}
                    {/*{this.props.clients.map((client) => {*/}
                    {/*    if (client)*/}
                    {/*        return (*/}
                    {/*            <option value={client._id} key={client._id}>*/}
                    {/*                {client.name}*/}
                    {/*            </option>*/}
                    {/*        );*/}
                    {/*    return null;*/}
                    {/*})}*/}
                  </select>
                </label>
              </div>
              <div>
                <label>Status</label>
                {this.props.activeCampaign.active ? 'Active' : 'Disabled'}
                <input
                  id="singleToggle"
                  name="active"
                  type="checkbox"
                  checked={this.props.activeCampaign.active ? true : false}
                  onChange={this.props.handlePreviewInputChange}
                />
                <label htmlFor="singleToggle" />
              </div>
              <div>
                <label>
                  Campaign End Date
                  <input
                    name="endDate"
                    type="date"
                    value={
                      this.props.activeCampaign.endDate
                        ? new Date(this.props.activeCampaign.endDate)
                          .toISOString()
                          .substr(0, 10)
                        : ''
                    }
                    onChange={this.props.handlePreviewInputChange}
                  />
                </label>
              </div>
              <div>
                <label>Notes</label>
                <textarea
                  name="notes"
                  onChange={this.props.handlePreviewInputChange}
                  className="formInput"
                  value={this.props.activeCampaign.notes || ''}
                />
              </div>
              <div>
                <h3>Sharing</h3>
                <div>
                  <button type="button" onClick={this.props.togglePublicLink}>
                    {this.props.activeCampaign &&
                      this.props.activeCampaign.publicLink &&
                      this.props.activeCampaign.publicLink.link
                      ? 'Destroy link'
                      : 'Generate link'}
                  </button>
                  <label>Public Link </label>
                  <textarea
                    readOnly
                    value={
                      this.props.activeCampaign &&
                        this.props.activeCampaign.publicLink
                        ? `https://${window.location.host}/shared/${this.props.activeCampaign.publicLink.link}`
                        : 'Not public'
                    }
                  />
                </div>
                <span>Link Expires</span>
                <input
                  name="publicLink__expires"
                  type="date"
                  value={
                    this.props.activeCampaign.publicLink &&
                      this.props.activeCampaign.publicLink.expires
                      ? new Date(this.props.activeCampaign.publicLink.expires)
                        .toISOString()
                        .substr(0, 10)
                      : ''
                  }
                  onChange={this.props.handlePreviewInputChange}
                />
              </div>
            </div>
          </div>
          <div className="tools">
            <div className="chapter">
              <h3>Banners</h3>

              {this.props.activeCampaign.projectID ? (
                <Link to={`/gallery?id=${this.props.activeCampaign.projectID}`}>
                  See Gallery
                </Link>
              ) : (
                ''
              )}

              <Link to={`/mixer?cid=${this.props.activeCampaign._id}`}>
                Create banners
              </Link>
              <button
                type="button"
                className="projectStartButton"
                onClick={this.props.toggleUploadModal}
              >
                Upload banner archive
              </button>
            </div>

            <div className="chapter">
              <h3> Your Accounts</h3>

              <div className="buttonGrouping">
                <button
                  className="googleConnect"
                  onClick={this.handleGoogleConnect}
                >
                  <img className="glogo" src="/g-logo.png" alt="google logo" />
                  <span>
                    {this.props.user.googleAccount &&
                      this.props.user.googleAccount.name
                      ? `Linked to ${this.props.user.googleAccount.name}`
                      : 'Connect to Google'}
                  </span>
                </button>
              </div>
              <div className="buttonGrouping">
                <button
                  className="facebookConnect"
                  onClick={this.handleFacebookConnect}
                >
                  <img
                    className="flogo"
                    src="/flogo-HexRBG-Wht-72.svg"
                    alt="facebook logo"
                  />
                  <span>
                    {this.props.user.facebookAccount && this.props.user.facebookAccount.name
                      ? `Linked to ${this.props.user.facebookAccount.name}`
                      : 'Connect to Facebook'}
                  </span>
                </button>
              </div>

            </div>

            <div className="chapter">
              <h3>Tools</h3>
              <div>
                {this.props.activeCampaign.projectID ? (
                  // <button
                  //   className="openScriptModal"
                  //   onClick={this.props.toggleScriptModal}
                  // >
                  //   Generate personalization script{" "}
                  // </button>
                  <Link to="/generateScript/">
                    Generate personalization script
                  </Link>
                ) : (
                  ''
                )}
                {this.props.activeCampaign.facebookAccount &&
                  this.props.activeCampaign.facebookAccount.name ? (
                  <button
                    className="openFacebookModal"
                    onClick={this.props.toggleFacebookModal}
                  >
                    Create Facebook Ads Campaign{' '}
                  </button>
                ) : (
                  ''
                )}
                {this.props.activeCampaign.googleAccount &&
                  this.props.activeCampaign.googleAccount.eid ? (
                  <Link to="/googlecampaign">Create Google Ads Campaign</Link>
                ) : (
                  ''
                )}
              </div>
            </div>
            <button
              className="dangerButton"
              onClick={this.props.deleteCampaign}
            >
              Delete Campaign
            </button>
          </div>
          <div className="save">
            <input
              id="formSaveButton"
              type="submit"
              value="Save"
              disabled={!this.props.unsavedContent}
            />
          </div>
        </React.Fragment>
      );

    return (
      <React.Fragment>
        <PreviewStyles
          className="campaignInfoForm"
          onChange={this.props.updateUnsavedContent}
          onSubmit={this.props.saveCampaign}
        >
          {this.props.uploadModalOpen ? this.uploadModal() : null}
          {/* <fieldset disabled={this.props.busy} aria-busy={this.props.busy}> */}
          {this.props.activeCampaign ? campaignFormFields : null}
          {/* </fieldset> */}
        </PreviewStyles>
      </React.Fragment>
    );
  }
}

Preview.propTypes = {
  updateUnsavedContent: PropTypes.func.isRequired,
  activeCampaign: PropTypes.object,
  showBanner: PropTypes.func,
  handlePreviewInputChange: PropTypes.func.isRequired,
  saveCampaign: PropTypes.func.isRequired,
  deleteCampaign: PropTypes.func.isRequired,
};
