import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

import CloseBtnModal from '../styles/CloseBtnModal';
import MixerPageStyles from '../styles/MixerPageStyles';

import './Styles/forms.scss';

const styles = (theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: '10000!important',
  },

  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: 566,
    border: 'none',
    outline: 'none',
    position: 'relative',
  },
});

class SendToMixerModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      size: 300,
      element: '',
      prefix: 'banner',
      html: true,
    };
  }

  onChangeHTML = (e) => {
    let value = e.target.checked;
    this.setState({ html: value });
    this.props.onHtml(value);
  };

  onChangePrefix = (e) => {
    let value = e.target.value;
    this.setState({ prefix: value });
    this.props.OnPrefix(value);
  };

  onChangeSize = (e) => {
    let value = e.target.value;
    this.setState({ size: value });
    this.props.onSize(value);
  };

  onChangeElement = (e) => {
    let value = e.target.value;
    this.setState({ element: value });
    this.props.onElement(value);
  };

  render() {
    const { classes, handleSendToMixer, handleClose } = this.props;
    const { props } = this;

    return (
      <>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={props.open}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{ timeout: 500 }}
        >
          <Fade in={props.open}>
            <div className={classes.paper}>
              <h2 id="transition-modal-title">Send To Mixer</h2>
              <CloseBtnModal onClick={handleClose}>x</CloseBtnModal>

              <div className="modal-content">
                <MixerPageStyles>
                  <form id="mainForm">
                    <fieldset>
                      <div className="singleToggle">
                        <label>Generate HTML files</label>
                        <input
                          id="generate_html"
                          name="generate_html"
                          type="checkbox"
                          checked={this.state.html}
                          onChange={this.onChangeHTML}
                        />
                        <label htmlFor="generate_html" />
                      </div>

                      <div>
                        <h3> Max image file size (kb)</h3>
                        <input
                          onChange={this.onChangeSize}
                          value={this.state.size}
                          type="number"
                          name="image_size"
                          placeholder="Leave blank for no limit"
                        />
                      </div>

                      <div>
                        <h3>Wait for this element to load:</h3>
                        <input
                          onChange={this.onChangeElement}
                          value={this.state.element}
                          type="text"
                          name="last_element"
                          placeholder=".last, #last_image, etc"
                        />
                      </div>

                      <div>
                        <h3>Prefix to name each generated image:</h3>
                        <input
                          type="text"
                          name="name_prefix"
                          onChange={this.onChangePrefix}
                          value={this.state.prefix}
                          placeholder="banner"
                          pattern="[a-zA-Z0-9_]{0,}"
                          title="Single word with no special characters. Stick to letters, numbers and _"
                        />
                      </div>
                    </fieldset>
                  </form>

                  <button
                    className="btn-default"
                    onClick={handleSendToMixer}
                    style={{ marginTop: '2rem' }}
                  >
                    Send To Mixer
                  </button>
                </MixerPageStyles>
              </div>
            </div>
          </Fade>
        </Modal>
      </>
    );
  }
}

export default withStyles(styles)(SendToMixerModal);
