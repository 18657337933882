import React, { Component } from 'react';

import { Dispatcher, Events } from '../../Events';

import '../styles.scss';
import FormStyles from '../../styles/FormStyles';
import fetchInstance from '../../../utils/fetchInstance';

class CampaignPicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cid: new URLSearchParams(window.location.search).get('cid') || false,
      clients: [],
      campaigns: [],
      selectedClient: false,
      selectedCampaign: false,
    };
  }

  componentDidMount = () => {
    if (this.state.cid) {
      this.fetchCampaign();
    }
    this.fetchClients();
  };

  fetchClients = async () => {
    try {
      const res = await fetchInstance('/getClients');
      if (!res.ok) {
        throw new Error(res.statusText);
      }
      const data = await res.json();

      if (!data.error) {
        this.setState({ clients: data.clients });
      } else {
        throw new Error(data.message);
      }
    } catch (err) {
      // this.props.setBanner(true, "bad", err.message);
    }
  };

  selectClient = (event) => {
    const selectedIndex = event.target.options.selectedIndex;
    const key = event.target.options[selectedIndex].getAttribute('data-key');

    if (!key) {
      this.setState(
        {
          selectedClient: false,
          selectedCampaign: false,
        },
        () => {
          this.fetchCampaigns();
        },
      );
    } else {
      let selectedClient = this.state.clients.filter((client) => {
        return client._id === key;
      });

      this.setState(
        {
          selectedClient: selectedClient[0],
          selectedCampaign: false,
        },
        () => {
          this.fetchCampaigns();
        },
      );
    }
  };

  fetchCampaigns = async () => {
    try {
      const res = await fetchInstance('/getClientCampaigns', {
        method: 'POST',
        body: JSON.stringify({
          id: this.state.selectedClient,
          order: 'campaign-first', // create an option how the campaign list should be populated, campaign first || campaign last ...
        }),
      });
      if (!res.ok) throw new Error(res.statusText);
      const data = await res.json();

      if (data.error) {
        throw new Error(data.message);
      }

      this.setState({ campaigns: data });
    } catch (err) {
      // this.props.setBanner(true, "bad", err.message);
    }
  };

  fetchCampaign = async () => {
    try {
      const res = await fetchInstance(`/getCampaign/${this.state.cid}`, {
        method: 'GET',
      });
      if (!res.ok) throw new Error(res.statusText);
      const data = await res.json();

      if (data.error) {
        throw new Error(data.message);
      }

      this.setState(
        {
          selectedClient: data.client,
          selectedCampaign: data,
        },
        () => {
          this.fetchCampaigns();
        },
      );
    } catch (err) {
      // this.props.setBanner(true, "bad", err.message);
    }
  }

  findCampaignById = (campaigns, key) => {
    for (let campaign of campaigns) {
      if (campaign._id === key) {
        return campaign;
      }
      if (campaign.isCampaignGroup && campaign.children) {
        let found = this.findCampaignById(campaign.children, key);
        if (found) return found;
      }
    }
    return null;
  }

  selectCampaign = (event) => {
    const selectedIndex = event.target.options.selectedIndex;
    const key = event.target.options[selectedIndex].getAttribute('data-key');

    if (!key) {
      this.setState({
        selectedCampaign: false,
      });
    } else {
      let selectedCampaign = this.findCampaignById(this.state.campaigns, key);

      if (selectedCampaign) {
        this.setState({
          selectedCampaign: selectedCampaign,
        });
      } else {
        this.setState({
          selectedCampaign: false,
        });
      }
    }

    Dispatcher.dispatch({
      event: Events.CAMPAIGN_SELECT,
      payload: {
        id: key,
      },
    });
  };

  sendToMixer = (e) => {
    e.preventDefault();
    Dispatcher.dispatch({
      event: Events.SEND_TO_MIXER,
    });
  };

  render() {
    return (
      <FormStyles
        style={{ position: 'absolute', bottom: '40px', width: '244px' }}
      >
        <div className="group">
          <label>Select a client</label>
          <select
            value={this.state.selectedClient.name}
            name="key"
            onChange={this.selectClient}
            data-testid="rule-key"
          >
            <option>Select One</option>
            {this.state.clients.map((client) => (
              <option
                data-testid="option"
                key={client._id}
                data-key={client._id}
                value={client.name}
              >
                {client.name}
              </option>
            ))}
          </select>
        </div>
        <div className="group">
          <label>Select a campaign</label>
          <select
            value={this.state.selectedCampaign.name}
            name="key"
            onChange={this.selectCampaign}
            data-testid="rule-key"
          >
            <option>Select One</option>
            {this.state.campaigns.map(
              (campaign, i) =>
                (campaign.isCampaignGroup && (
                  <optgroup key={i} label={campaign.name}>
                    {campaign.children.map((child) => (
                      <option
                        data-testid="option"
                        key={child._id + i}
                        data-key={child._id}
                        value={child.name}
                      >
                        {child.name}
                      </option>
                    ))}
                  </optgroup>
                )) || (
                  <option
                    data-testid="option"
                    key={campaign._id + i}
                    data-key={campaign._id}
                    value={campaign.name}
                  >
                    {campaign.name}
                  </option>
                ),
            )}
          </select>
        </div>
        <button className="confirm-button" onClick={this.sendToMixer}>
          Send to Mixer
        </button>
      </FormStyles>
    );
  }
}

export default CampaignPicker;
