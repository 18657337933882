import React, { Component } from 'react';
import BannerModal from '../../BannerModal';

// bgimg options
import BannerBackgroundImageOptions from './BannerBackgroundOptions';

class BannerBackgroundButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  render() {
    const { state, handleOpen, handleClose } = this;

    return (
      <>
        <button className="confirm-button" onClick={handleOpen}>
          Set Background Image
        </button>

        <BannerModal
          title="Set background image"
          open={state.open}
          handleClose={handleClose}
        >
          <BannerBackgroundImageOptions closeModal={handleClose} />
        </BannerModal>
      </>
    );
  }
}

export default BannerBackgroundButton;
