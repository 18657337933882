import React, { Component } from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom'
import styled from 'styled-components'
import { ThemeProvider } from 'styled-components'
import GlobalStyles, { globalTheme } from './Components/styles/GlobalStyles'

import Wrapper from './Components/Wrapper'
import Header from './Components/Header'
import Footer from './Components/Footer'
import Banner from './Components/Banner'

import SignUp from './Components/SignUp'
import ResetPassword from './Components/ResetPassword'
import Login from './Components/Login'

import Gallery from './Components/Gallery'
import Mixer from './Components/Mixer'
import BannerCreator from './Components/BannerCreator/BannerCreator'
import ScriptPage from './Components/ScriptPage'
import GoogleAdsPage from './Components/GoogleAdsPage'
import FacebookAdsPage from './Components/FacebookAdsPage'
import CombinationsPage from './Components/CombinationsPage'

import Preview from './Components/Preview'
import CampaignsMainPage from './Components/CampaignsMainPage'
import CampaignPage from './Components/CampaignPage'
import Dashboard from './Components/Dashboard'
import ResetPasswordPage from './Components/ResetPasswordPage'
import UserPage from './Components/UserPage'
import ClientsMainPage from './Components/ClientsMainPage'
// new Client Page
import ClientsMain from './Components/ClientsMain'
import ClientPage from './Components/ClientPage'
import ClientPreview from './Components/ClientPreview'
import ClientRulesPage from './Components/ClientRulesPage'

import AIMainPage from './Components/ArtificialIntelligence/AIMainPage'
import AISettingsPage from './Components/ArtificialIntelligence/AISettingsPage'
import ClientContext from './Components/ClientContext'
import CampaignContext from './Components/CampaignContext'
import GeneralModalProvider from './Components/GeneralModal'

// Campaign Section
import CampaignPreview from './Components/Campaigns/CampaignPreview'
import CampaignsMain from './Components/CampaignsMain'

import ManageUsers from './Components/ManageUsers'

import CampaignProcess from './Components/CampaignProcess'
import fetchInstance from './utils/fetchInstance'
import PrivacyPolicy from './Components/PrivacyPolicy'

const AppContainer = styled.div`
min-height: 100vh;
display: flex;
flex-direction: column;
`;

const MainContent = styled.main`
flex: 1;
`;

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      user: null,
      campaigns: null,
      visibleCampaigns: null,
      loadingCampaigns: true,
      activeCampaign: null,
      searchText: null,
      bannerType: null,
      bannerText: '',
      bannerShowing: false,
      unsavedContent: false,
      error: null,
      uploadModalOpen: false,
      selectedArchive: null,
      uploadingFiles: false,
      // scriptModalOpen: false,
      facebookModalOpen: false,
      overwrite: true,
      previewBusy: false,
      clients: [],
      visibleClients: [],
      activeClient: null,
      client: null,
    }
  }

  fetchCampaigns = async (cid) => {
    try {
      const res = await fetchInstance('/getCampaigns')
      if (!res.ok) throw new Error(res.statusText)
      const data = await res.json()
      if (!data.error) {
        let activeCampaign = null
        cid =
          cid ||
          new URLSearchParams(document.location.search.substring(1)).get('cid')
        const err = new URLSearchParams(
          document.location.search.substring(1),
        ).get('err')
        if (cid) {
          activeCampaign = data.filter((campaign) => campaign._id === cid)[0]
        }
        this.setState(
          {
            campaigns: data,
            visibleCampaigns: data,
            loadingCampaigns: false,
            activeCampaign,
          },
          () => {
            if (err) return this.setBanner(true, 'bad', err, false, 5000)
          },
        )
      } else {
        throw new Error(data.message)
      }
    } catch (err) {
      this.setBanner(true, 'bad', err.message)
    }
  }

  fetchClients = async () => {
    try {
      const res = await fetchInstance('/getClients')
      if (!res.ok) throw new Error(res.statusText)
      const data = await res.json()
      if (!data.error) {
        let activeClient = null
        this.setState({
          clients: data,
          visibleClients: data,
          loadingClients: false,
          activeClient,
        })
      } else {
        throw new Error(data.message)
      }
    } catch (err) {
      this.setBanner(true, 'bad', err.message)
    }
  }

  checkUser = async () => {
    const token = localStorage.getItem('token');
    if (token) {
      return fetchInstance('/checkuser')
        .then((res) => {
          if (res.ok) return res.json()
          throw new Error(res.status + ' ' + res.statusText)
        })
        .then((data) => this.setState({ user: data.user }))
        .catch((err) => this.setBanner(true, 'bad', err.message))
    }

  }
  setUser = (user) => {
    return this.setState({ user })
  }
  logout = () => {
    try {
      localStorage.removeItem('token')
      this.setUser(undefined)
      window.location.href = '/'
    } catch (error) {
      this.setBanner(true, 'bad', `Couldn't log out`)
    }
  }

  handleArchiveInputChange = (e) => {
    e.stopPropagation()
    const archiveInput = e.target
    const file = archiveInput.files[0]
    this.setState({ selectedArchive: file })
  }
  handleArchiveDrop = (e) => {
    e.preventDefault()
    e.stopPropagation()
    const file = e.dataTransfer.items[0].getAsFile()
    if (file.type !== 'application/x-zip-compressed') return
    this.setState({ selectedArchive: file })
  }

  toggleUploadModal = (e) => {
    e.stopPropagation()
    if (
      e.target.classList.value !== 'archiveSelectModal' &&
      e.target.classList.value !== 'projectStartButton'
    )
      return
    const currentState = this.state.uploadModalOpen
    this.setState({ uploadModalOpen: !currentState, selectedArchive: null })
  }

  toggleScriptModal = (e = null) => {
    if (e) {
      e.stopPropagation()
      if (
        e.target.classList.value !== 'closeScriptModalX' &&
        e.target.classList.value !== 'openScriptModal'
      )
        return
    }
    const currentState = this.state.scriptModalOpen
    this.setState({ scriptModalOpen: !currentState })
  }
  toggleFacebookModal = (e = null) => {
    if (e) {
      e.stopPropagation()
      if (
        e.target.classList.value !== 'closeFacebookModalX' &&
        e.target.classList.value !== 'openFacebookModal'
      )
        return
    }
    const currentState = this.state.facebookModalOpen
    this.setState({ facebookModalOpen: !currentState })
  }
  uploadArchive = (e) => {
    e.stopPropagation()
    this.setState({ uploadingFiles: true })
    let form = new FormData()
    form.append(
      'archive',
      this.state.selectedArchive,
      this.state.selectedArchive.name,
    )
    form.append('overwrite', this.state.overwrite)

    fetchInstance(`/uploadbanners?cid=${this.state.activeCampaign._id}`, {
      method: 'post',
      body: form,
    })
      .then((res) => {
        if (!res.ok) throw new Error(res.statusText)
        return res.json()
      })
      .then((data) => {
        if (data.success) {
          const activeCampaign = { ...this.state.activeCampaign }
          activeCampaign.projectID = data.projectID
          this.setState(
            {
              uploadModalOpen: false,
              selectedArchive: null,
              activeCampaign,
              uploadingFiles: false,
            },
            this.updateUnsavedContent,
          )
        } else {
          this.setBanner(true, 'bad', data.message, false, 5000)
          this.setState({
            uploadingFiles: false,
          })
        }
      })
      .catch((err) => {
        this.setBanner(true, 'bad', err.message, false, 5000)
        this.setState({
          uploadingFiles: false,
        })
      })
  }

  setActiveCampaign = (ev) => {
    const campaigns = [...this.state.campaigns]
    let activeCampaign = { ...this.state.activeCampaign }
    // get current check if same return
    if (activeCampaign && activeCampaign._id === ev.target.dataset.id) return
    // check if currently uploading files
    if (this.state.uploadingFiles) {
      const userAction = window.confirm(
        'You are currently uploading files. If you navigate away the process will be interrupted and you may lose data. Press cancel to let the process finish',
      )
      if (userAction) {
      } else {
        return
      }
    }
    // check if current preview campaign has been changed
    const unsaved = this.state.unsavedContent
    if (unsaved) {
      // if there's unsaved stuff warn the user
      const userAction = window.confirm(
        "You have unsaved changes in your campaign. If you press OK they'll be lost. Press cancel and click the Save button to keep them",
      )
      if (userAction) {
      } else {
        return
      }
    }
    activeCampaign = campaigns.filter(
      (campaign) => campaign._id === ev.target.dataset.id,
    )[0]
    console.log(activeCampaign)
    // if it hasn't change active campaign
    this.setState({
      activeCampaign,
      uploadingFiles: false,
      uploadModalOpen: false,
      unsavedContent: false,
      bannerType: null,
      bannerText: '',
      bannerShowing: false,
    })
  }

  setActiveClient = (ev) => {
    const clients = [...this.state.clients]
    let activeClient = { ...this.state.activeClient }
    // get current check if same return
    if (activeClient && activeClient._id === ev.target.dataset.id) return
    // check if current preview campaign has been changed
    const unsaved = this.state.unsavedContent
    if (unsaved) {
      // if there's unsaved stuff warn the user
      const userAction = window.confirm(
        "You have unsaved changes in your campaign. If you press OK they'll be lost. Press cancel and click the Save button to keep them",
      )
      if (userAction) {
      } else {
        return
      }
    }
    activeClient = clients.filter(
      (client) => client._id === ev.target.dataset.id,
    )[0]

    // if it hasn't change active campaign
    this.setState({
      activeClient,
      unsavedContent: false,
      bannerType: null,
      bannerText: '',
      bannerShowing: false,
    })
  }
  // updateActiveCampaign = update => {
  //
  //   return this.setState({ activeCampaign: update });
  // };

  handleSearchCampaignsInput = (ev) => {
    const txt = ev.target.value.toLowerCase()
    const campaigns = [...this.state.campaigns]
    if (txt.length < 3) {
      this.setState({ visibleCampaigns: campaigns })
      return
    }

    // eslint-disable-next-line
    const visibleCampaigns = campaigns.filter((campaign) => {
      if (
        (campaign.name && campaign.name.toLowerCase().includes(txt)) ||
        (campaign.notes && campaign.notes.toLowerCase().includes(txt)) ||
        (campaign.domain && campaign.domain.toLowerCase().includes(txt))
      )
        return campaign
    })
    this.setState({ visibleCampaigns })
  }

  // handleSearchClientsInput = (ev) => {
  //     const txt = ev.target.value.toLowerCase();
  //     const clients = [...this.state.clients];

  //     this.setState({ visibleClients: clients });

  //     // eslint-disable-next-line
  //     const visibleClients = clients.filter((client) => {
  //         if ((client.name && client.name.toLowerCase().includes(txt)) || (client.notes && client.notes.toLowerCase().includes(txt))) return client;
  //     });

  //     this.setState({ visibleClients });
  // };

  updateUnsavedContent = () => {
    console.log('Update content')
    const unsavedContentMessage =
      'You have unsaved content. Make sure to save before navigating away'

    this.setState(
      { unsavedContent: true },
      this.setBanner(true, 'bad', unsavedContentMessage, true),
    )
  }
  toggleOverwrite = (ev) => {
    return this.setState({ overwrite: ev.target.checked })
  }

  togglePublicLink = () => {
    this.setState({ previewBusy: true })
    let campaign = { ...this.state.activeCampaign }
    // fetch and post to API
    return fetchInstance('/togglePublicLink', {
      method: 'POST',
      body: JSON.stringify({
        cid: campaign._id,
        delete: !!(campaign.publicLink && campaign.publicLink.link),
        expires: campaign.publicLink ? campaign.publicLink.expires : null,
      }),
    })
      .then((res) => {
        if (!res.ok) throw new Error(res.statusText)
        return res.json()
      })
      .then((data) => {
        if (data.success) return data.campaign
        if (data.error) throw new Error(data.message)
      })
      .then((updatedCampaign) => {
        // if res.ok get back campaign from server and set that
        let campaigns = [...this.state.campaigns]
        let found = false
        campaigns = campaigns.map((current) => {
          if (current._id === updatedCampaign._id) {
            current = updatedCampaign
            found = true
          }
          return current
        })
        if (!found) {
          campaigns.unshift(updatedCampaign)
        }
        return this.setState(
          {
            campaigns,
            visibleCampaigns: campaigns,
            activeCampaign: updatedCampaign,
            unsavedContent: false,
            previewBusy: false,
          },
          this.setBanner(true, 'good', 'Content saved successfully'),
        )
      })
      .catch((err) =>
        this.setState(
          { previewBusy: false },
          this.setBanner(true, 'bad', err.message, false, 5000),
        ),
      )
  }
  resetVisibles = () => {
    // used to reset the visible stuff after searching
    return this.setState({
      visibleCampaigns: this.state.campaigns,
      visibleClients: this.state.clients,
    })
  }

  setBanner = (
    showing = true,
    type,
    text = "",
    permanent = false,
    timeoutDuration = 3000,
  ) => {
    clearTimeout(this.bannerTimeout)
    this.setState(
      { bannerShowing: showing, bannerType: type, bannerText: text },
      () => {
        if (permanent) return
        this.bannerTimeout = setTimeout(() => {
          this.setState({
            bannerShowing: false,
            bannerType: null,
            bannerText: '',
          })
        }, timeoutDuration)
      },
    )
  }
  resetBanner = () => {
    return this.setBanner(false, null, '')
  }

  handlePreviewInputChange = (ev) => {
    const activeCampaign = { ...this.state.activeCampaign }
    const changedProperty = ev.currentTarget.name
    let newValue = ev.currentTarget.value
    if (ev.currentTarget.type === 'checkbox') {
      newValue = ev.currentTarget.checked
    }
    if (ev.currentTarget.type === 'select-one') {
      if (ev.currentTarget.value === 'Not selected') {
        newValue = null
      }
    }
    if (changedProperty === 'publicLink__expires') {
      if (!activeCampaign.publicLink) activeCampaign.publicLink = {}
      activeCampaign.publicLink.expires = newValue
    } else {
      activeCampaign[changedProperty] = newValue
    }

    this.setState({ activeCampaign })
  }

  handleClientPreviewInputChange = (ev) => {
    const activeClient = { ...this.state.activeClient }
    const changedProperty = ev.currentTarget.name
    let newValue = ev.currentTarget.value
    if (ev.currentTarget.type === 'checkbox') {
      newValue = ev.currentTarget.checked
    }
    activeClient[changedProperty] = newValue
    this.setState({ activeClient })
  }

  // unlinkSocialAccount = ev => {
  //   ev.persist();
  //   ev.stopPropagation();
  //   this.setState({ previewBusy: true, unsavedContent: true }, () => {
  //     const activeCampaign = { ...this.state.activeCampaign };
  //     const changedProperty = ev.target.name;
  //     activeCampaign[changedProperty] = true;
  //     this.setState({ activeCampaign }, () => this.saveCampaign(ev));
  //   });
  // };

  addCampaign = () => {
    this.setState({ activeCampaign: {} })
  }

  addClient = () => {
    this.setState({ activeClient: {} })
  }

  saveCampaign = (ev) => {
    ev.stopPropagation()
    ev.preventDefault()
    if (!this.state.unsavedContent) return
    this.setState({ previewBusy: true })
    let campaign = { ...this.state.activeCampaign }
    // fetch and post to API
    new Promise((resolve, reject) => {
      if (!campaign._id) {
        resolve(
          fetchInstance('/campaign', {
            method: 'post',
            body: JSON.stringify({ campaign: campaign }),
          }),
        )
      } else {
        resolve(
          fetchInstance('/campaign', {
            method: 'put',
            body: JSON.stringify({ campaign: campaign }),
          }),
        )
      }
    })
      .then((res) => {
        if (!res.ok) throw new Error(res.statusText)
        return res.json()
      })
      .then((data) => {
        if (data.success) return data.campaign
        if (data.error) throw new Error(data.message)
      })
      .then((updatedCampaign) => {
        // if res.ok get back campaign from server and set that
        let campaigns = [...this.state.campaigns]
        let found = false
        campaigns = campaigns.map((current) => {
          if (current._id === updatedCampaign._id) {
            current = updatedCampaign
            found = true
          }
          return current
        })
        if (!found) {
          campaigns.unshift(updatedCampaign)
        }
        return this.setState(
          {
            campaigns,
            visibleCampaigns: campaigns,
            activeCampaign: updatedCampaign,
            unsavedContent: false,
            previewBusy: false,
          },
          this.setBanner(true, 'good', 'Content saved successfully'),
        )
      })
      .catch((err) =>
        this.setState(
          { previewBusy: false },
          this.setBanner(true, 'bad', err.message, false, 5000),
        ),
      )
  }

  saveClient = (ev) => {
    ev.stopPropagation()
    ev.preventDefault()
    if (!this.state.unsavedContent) return
    this.setState({ previewBusy: true })
    let client = { ...this.state.activeClient }
    // fetch and post to API
    new Promise((resolve, reject) => {
      if (!client._id) {
        resolve(
          fetchInstance('/client', {
            method: 'post',
            body: JSON.stringify({ client: client }),
          }),
        )
      } else {
        resolve(
          fetchInstance('/client', {
            method: 'put',
            body: JSON.stringify({ client: client }),
          }),
        )
      }
    })
      .then((res) => {
        if (!res.ok) throw new Error(res.statusText)
        return res.json()
      })
      .then((data) => {
        if (data.success) return data.client
        if (data.error) throw new Error(data.message)
      })
      .then((updatedClient) => {
        // if res.ok get back campaign from server and set that
        let clients = [...this.state.clients]
        let found = false
        clients = clients.map((current) => {
          if (current._id === updatedClient._id) {
            current = updatedClient
            found = true
          }
          return current
        })
        if (!found) {
          clients.unshift(updatedClient)
        }
        return this.setState(
          {
            clients,
            visibleClients: clients,
            activeClient: updatedClient,
            unsavedContent: false,
            previewBusy: false,
          },
          this.setBanner(true, 'good', 'Content saved successfully'),
        )
      })
      .catch((err) =>
        this.setState(
          { previewBusy: false },
          this.setBanner(true, 'bad', err.message, false, 5000),
        ),
      )
  }

  deleteCampaign = (ev) => {
    const campaign = { ...this.state.activeCampaign }
    const accept = window.confirm(
      "You're about to PERMANENTLY DELETE this campaign and all data associated with it. Are you sure?",
    )
    if (!accept) return
    const secondConfirm = window.confirm(
      "You're DELETING ALL DATA. You can NEVER GET IT BACK. Are you really sure? ",
    )
    if (!secondConfirm) return
    // fetch and post to API
    fetchInstance('/campaign', {
      method: 'delete',
      body: JSON.stringify({ campaign }),
    })
      .then((res) => {
        if (res.ok) return res.json()
        throw new Error(res.status + ' ' + res.statusText)
      })
      .then((data) => {
        if (data.error) throw new Error(data.message)
        let campaigns = [...this.state.campaigns]
        campaigns = campaigns.filter((current) => {
          if (current._id === campaign._id) return false
          return current
        })
        this.setState({
          campaigns,
          activeCampaign: null,
          visibleCampaigns: campaigns,
        })
      })
      .catch((err) => this.setBanner(true, 'bad', err.message, false, 5000))
  }

  deleteClient = (ev) => {
    const client = { ...this.state.activeClient }
    console.log(client)
    const accept = window.confirm(
      "You're about to PERMANENTLY DELETE this client. Are you sure?",
    )
    if (!accept) return
    const secondConfirm = window.confirm(
      "You're DELETING ALL DATA. You can NEVER GET IT BACK. Are you really sure? ",
    )
    if (!secondConfirm) return
    // fetch and post to API
    fetchInstance('/client', {
      method: 'delete',
      body: JSON.stringify({ client }),
    })
      .then((res) => {
        if (res.ok) return res.json()
        throw new Error(res.status + ' ' + res.statusText)
      })
      .then((data) => {
        if (data.error) throw new Error(data.message)
        let clients = [...this.state.clients]
        clients = clients.filter((current) => current._id !== client._id)
        this.setState({
          clients,
          activeClient: null,
          visibleClients: clients,
        })
      })
      .catch((err) => this.setBanner(true, 'bad', err.message, false, 5000))
  }

  addRule = (rule) => {
    const clientId = this.state.activeClient._id
    if (!rule.key || !rule.value)
      return this.setBanner(
        true,
        'bad',
        'Rule must have both key and value selected',
      )
    if (!clientId)
      return this.setBanner(true, 'bad', 'Please select a client and try again')
    return fetchInstance('/rules', {
      method: 'PUT',
      body: JSON.stringify({ rule, clientId }),
    })
      .then((res) => {
        if (!res.ok) throw new Error(res.status + ' ' + res.statusText)
        return res.json()
      })
      .then((data) => {
        if (data.error) throw new Error(data.message)
        if (data.success) {
          const activeClient = { ...this.state.activeClient }
          activeClient.brandGuidelines = [...activeClient.brandGuidelines]
          activeClient.brandGuidelines.unshift(rule)
          return this.setState({ activeClient })
        }
      })
      .catch((err) => this.setBanner(true, 'bad', err.message))
  }

  deleteRule = (rule) => {
    const clientId = this.state.activeClient._id
    if (!rule.key || !rule.value)
      return this.setBanner(
        true,
        'bad',
        'Rule must have both key and value selected',
      )
    if (!clientId)
      return this.setBanner(true, 'bad', 'Please select a client and try again')
    return fetchInstance('/rules', {
      method: 'DELETE',
      body: JSON.stringify({ rule, clientId }),
    })
      .then((res) => {
        if (!res.ok) throw new Error(res.status + ' ' + res.statusText)
        return res.json()
      })
      .then((data) => {
        if (data.error) throw new Error(data.message)
        if (data.success) {
          const activeClient = { ...this.state.activeClient }
          activeClient.brandGuidelines = activeClient.brandGuidelines.filter(
            (existingRule) =>
              existingRule.key !== rule.key ||
              existingRule.value !== rule.value,
          )
          return this.setState({ activeClient })
        }
      })
      .catch((err) => this.setBanner(true, 'bad', err.message))
  }

  extractCSRF = () => {
    try {
      const csrfCookie = document.cookie
        .split(';')
        .filter((part) => part.indexOf('xsrf-token') >= 0)
      if (!csrfCookie[0]) return null
      const csrfToken = csrfCookie[0].split('=')[1]
      if (!csrfToken) throw new Error('No CSRF')
      return csrfToken
    } catch (err) {
      console.log(err)
      return this.setBanner(
        true,
        'bad',
        'Request cannot be completed. Refresh page and try again',
        true,
      )
    }
  }

  componentDidMount() {
    //  fetch campaigns from API

    this.checkUser().then(async () => {
      if (this.state.user)
        try {
          await Promise.all([this.fetchCampaigns, this.fetchClients])
        } catch (err_1) {
          return this.setBanner(true, 'bad', err_1.message, true)
        }
    })
  }

  componentDidUpdate(preProps, prevState) {
    if (prevState.user !== this.state.user) {
      Promise.all([this.fetchCampaigns(), this.fetchClients()]).catch((err) => {
        return this.setBanner(
          true,
          'bad',
          'Could not fetch campaigns and clients. Try refreshing the page',
        )
      })
    }
  }

  render() {
    return (
      <ThemeProvider theme={globalTheme}>
        <AppContainer>
          <GlobalStyles />
          <Router>
            <MainContent>
              <Wrapper resetBanner={this.resetBanner}>
                <div className="App">
                  <Banner
                    bannerShowing={this.state.bannerShowing}
                    bannerType={this.state.bannerType}
                    bannerText={this.state.bannerText}
                  />
                  <Header user={this.state.user} logout={this.logout} />
                  {this.state.user ? (
                    <>
                      <Route
                        exact
                        path="/"
                        render={() => {
                          return <Redirect to="/clients" />;
                        }}
                      />
                      <GeneralModalProvider>
                        <Route
                          path="/clients"
                          exact
                          render={() => (
                            <ClientsMain
                              extractCSRF={this.extractCSRF}
                              setBanner={this.setBanner}
                            />
                          )}
                        />

                        <Route
                          path="/client/:id"
                          render={({ match }) => (
                            <CampaignsMain
                              setActiveCampaign={this.setActiveCampaign}
                              match={match}
                              extractCSRF={this.extractCSRF}
                              setBanner={this.setBanner}
                              user={this.state.user}
                            />
                          )}
                        />

                        {this.state.user.sudo && (
                          <Route
                            path="/manage-users"
                            render={() => (
                              <ManageUsers
                                extractCSRF={this.extractCSRF}
                                setBanner={this.setBanner}
                              />
                            )}
                          />
                        )}

                        <Route
                          path="/process/:id"
                          render={({ match }) => (
                            <CampaignProcess
                              exact
                              match={match}
                              extractCSRF={this.extractCSRF}
                            />
                          )}
                        />

                        <Route
                          path="/campaign/:id/:shared?"
                          render={({ props, match }) => (
                            <CampaignPreview
                              key={match.params['id']}
                              props={props}
                              match={match}
                              extractCSRF={this.extractCSRF}
                              setBanner={this.setBanner}
                              user={this.state.user}
                            />
                          )}
                        />

                        <Route
                          path="/dashboard"
                          render={() => (
                            <Dashboard
                              user={this.state.user}
                              setBanner={this.setBanner}
                              extractCSRF={this.extractCSRF}
                            />
                          )}
                        />

                        <Route
                          path="/mixer"
                          render={() => (
                            <Mixer
                              setBanner={this.setBanner}
                              extractCSRF={this.extractCSRF}
                            />
                          )}
                        />

                        <Route
                          path="/profile"
                          render={() => (
                            <UserPage
                              user={this.state.user}
                              setBanner={this.setBanner}
                              extractCSRF={this.extractCSRF}
                              checkUser={this.checkUser}
                              logout={this.logout}
                            />
                          )}
                        />

                        <Route
                          path="/facebookCampaign/:id"
                          render={(match) => (
                            <FacebookAdsPage
                              match={match}
                              // toggleFacebookModal={this.toggleFacebookModal}
                              setBanner={this.setBanner}
                              extractCSRF={this.extractCSRF}
                              user={this.state.user}
                            />
                          )}
                        />

                        <Route
                          path="/googleCampaign/:id"
                          render={(match) => (
                            <GoogleAdsPage
                              match={match}
                              // cid={this.state.activeCampaign ? this.state.activeCampaign._id : ""}
                              eid={
                                this.state.user
                                  ? this.state.user.googleAccount.eid
                                  : ''
                              }
                              user={this.state.user}
                              setBanner={this.setBanner}
                              extractCSRF={this.extractCSRF}
                            />
                          )}
                        />
                      </GeneralModalProvider>

                      <CampaignContext.Provider
                        value={{
                          campaigns: this.state.campaigns,
                          visibleCampaigns: this.state.visibleCampaigns,
                          loadingCampaigns: this.state.loadingCampaigns,
                          setActiveCampaign: this.setActiveCampaign,
                          activeCampaign: this.state.activeCampaign,
                          handleSearchCampaignsInput:
                            this.handleSearchCampaignsInput,
                          addCampaign: this.addCampaign,
                          user: this.state.user,
                          resetVisibles: this.resetVisibles,
                        }}
                      >
                        <ClientContext.Provider
                          value={{
                            extractCSRF: this.extractCSRF,
                            clients: this.state.clients,
                            visibleClients: this.state.visibleClients,
                            loadingClients: this.state.loadingClients,
                            setActiveClient: this.setActiveClient,
                            activeClient: this.state.activeClient,
                            // handleSearchClientsInput: this.handleSearchClientsInput,
                            addClient: this.addClient,
                            user: this.state.user,
                            handlePreviewInputChange:
                              this.handleClientPreviewInputChange,
                            updateUnsavedContent: this.updateUnsavedContent,
                            unsavedContent: this.state.unsavedContent,
                            saveClient: this.saveClient,
                            deleteClient: this.deleteClient,
                            resetVisibles: this.resetVisibles,
                            setBanner: this.setBanner,
                            addRule: this.addRule,
                            deleteRule: this.deleteRule,
                          }}
                        >
                          <Route
                            path="/campaigns-old"
                            exact
                            render={() => (
                              <CampaignsMainPage extractCSRF={this.extractCSRF} />
                            )}
                          />

                          <Route
                            path="/clients-old"
                            render={() => (
                              <ClientsMainPage
                                extractCSRF={this.extractCSRF}
                                clients={this.state.clients}
                                visibleClients={this.state.visibleClients}
                                loadingClients={this.state.loadingClients}
                                setActiveClient={this.setActiveClient}
                                activeClient={this.state.activeClient}
                                handleSearchCampaignsInput={
                                  this.handleSearchCampaignsInput
                                }
                                addClient={this.addClient}
                                user={this.state.user}
                              />
                            )}
                          />

                          <Route
                            path="/client/old"
                            render={() => (
                              <ClientPage>
                                <ClientPreview />
                              </ClientPage>
                            )}
                          />
                          <Route
                            path="/clientrules"
                            render={() => (
                              <ClientPage>
                                <ClientRulesPage />
                              </ClientPage>
                            )}
                          />

                          <Route
                            path="/campaign"
                            exact
                            render={() => (
                              <CampaignPage>
                                <Preview
                                  user={this.state.user}
                                  busy={this.state.previewBusy}
                                  setBanner={this.setBanner}
                                  updateUnsavedContent={this.updateUnsavedContent}
                                  unsavedContent={this.state.unsavedContent}
                                  activeCampaign={this.state.activeCampaign}
                                  clients={this.state.clients}
                                  activeClient={this.state.activeClient}
                                  updateActiveCampaign={this.updateActiveCampaign}
                                  handlePreviewInputChange={
                                    this.handlePreviewInputChange
                                  }
                                  saveCampaign={this.saveCampaign}
                                  deleteCampaign={this.deleteCampaign}
                                  toggleUploadModal={this.toggleUploadModal}
                                  uploadModalOpen={this.state.uploadModalOpen}
                                  handleArchiveInputChange={
                                    this.handleArchiveInputChange
                                  }
                                  handleArchiveDrop={this.handleArchiveDrop}
                                  selectedArchive={this.state.selectedArchive}
                                  uploadingFiles={this.state.uploadingFiles}
                                  uploadArchive={this.uploadArchive}
                                  // toggleFacebookModal={this.toggleFacebookModal}
                                  toggleOverwrite={this.toggleOverwrite}
                                  overwrite={this.state.overwrite}
                                  unlinkSocialAccount={this.unlinkSocialAccount}
                                  extractCSRF={this.extractCSRF}
                                  togglePublicLink={this.togglePublicLink}
                                />
                              </CampaignPage>
                            )}
                          />

                          <Route
                            path="/generateScript/:id"
                            render={({ match }) => (
                              <ScriptPage
                                match={match}
                                // activeCampaign={this.state.activeCampaign}
                                // toggleScriptModal={this.toggleScriptModal}
                                setBanner={this.setBanner}
                                extractCSRF={this.extractCSRF}
                              />
                            )}
                          />

                          <Route
                            path="/gallery"
                            render={() => (
                              <CampaignPage>
                                <Gallery
                                  activeCampaign={this.state.activeCampaign}
                                  setBanner={this.setBanner}
                                  extractCSRF={this.extractCSRF}
                                />
                              </CampaignPage>
                            )}
                          />
                          <Route
                            path="/shared/:publicLink"
                            exact
                            render={({ match }) => (
                              <Gallery
                                setBanner={this.setBanner}
                                extractCSRF={this.extractCSRF}
                              />
                            )}
                          />

                          <Route
                            path="/banner-creator"
                            render={() => (
                              <BannerCreator extractCSRF={this.extractCSRF} />
                            )}
                          />
                          <Route
                            path="/ai-output"
                            render={() => (
                              <AIMainPage extractCSRF={this.extractCSRF()} />
                            )}
                          />
                          <Route
                            path="/ai-output-settings"
                            render={() => (
                              <AISettingsPage extractCSRF={this.extractCSRF()} />
                            )}
                          />
                          <Route
                            path="/combinations"
                            render={() => (
                              <CombinationsPage
                                activeCampaign={this.state.activeCampaign}
                                setBanner={this.setBanner}
                                extractCSRF={this.extractCSRF}
                              />
                            )}
                          />
                        </ClientContext.Provider>
                      </CampaignContext.Provider>
                      <Route
                        path="/privacy-policy"
                        exact
                        render={() => <PrivacyPolicy />}
                      />
                    </>
                  ) : (
                    <Switch>
                      <Route
                        path="/privacy-policy"
                        exact
                        render={() => <PrivacyPolicy />}
                      />
                      <Route
                        path="/reset/:token"
                        exact
                        render={() => (
                          <ResetPasswordPage
                            setBanner={this.setBanner}
                            extractCSRF={this.extractCSRF}
                          />
                        )}
                      />
                      <Route
                        path="/shared/:publicLink"
                        exact
                        render={() => (
                          <Gallery
                            setBanner={this.setBanner}
                            extractCSRF={this.extractCSRF}
                          />
                        )}
                      />
                      <SignUp
                        setUser={this.setUser}
                        setBanner={this.setBanner}
                        extractCSRF={this.extractCSRF}
                        path="/sign-up"
                        exact
                      />
                      <ResetPassword
                        setUser={this.setUser}
                        setBanner={this.setBanner}
                        extractCSRF={this.extractCSRF}
                        path="/reset-password"
                        exact
                      />
                      <Login
                        setUser={this.setUser}
                        setBanner={this.setBanner}
                        extractCSRF={this.extractCSRF}
                      />
                    </Switch>
                  )}
                </div>
              </Wrapper>
            </MainContent>
            <Footer user={this.state.user} />
          </Router>
        </AppContainer>
      </ThemeProvider>
    );
  }
}

export default App
