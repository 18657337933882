import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import fetchInstance from '../utils/fetchInstance';

class CampaignProcess extends Component {
  constructor(props) {
    super(props);
    // this.state = {
    //     loadingCustomers: true,
    //     customers: [],
    //     selectedCustomer: null,
    //     loadingCampaigns: false,
    //     campaigns: [],
    //     visibleCampaigns: [],
    //     selectedCampaign: null,
    //     loadingAdGroups: false,
    //     adGroups: [],
    //     visibleAdGroups: [],
    //     selectedAdGroup: null,
    //     creatingNewAdGroup: false,
    //     creatingAd: false,
    //     newAdGroup: { name: "", status: "PAUSED" },
    //     newAd: { name: "", displayUrl: "", finalUrl: "", images: "" },
    //     url: {
    //         website: "",
    //         source: "",
    //         medium: "",
    //         name: "",
    //         term: "",
    //         content: "{BannerID}"
    //     },
    //
    //     googleErrors: [],
    //     projectGroups: []
    // };
    this.pid = this.props.match.params.id;
  }

  componentDidMount() {
    // Get campaigns
    this.getByProjectID();
  }

  getByProjectID = () => {
    fetchInstance('/getProjectById', {
      method: 'POST',
      body: JSON.stringify({ projectID: this.pid }),
    })
      .then((res) => {
        if (res.ok) return res.json();
        throw new Error(res.status + ' ' + res.statusText);
      })
      .then((data) => {
        if (data.error) throw new Error(data.message);
        console.log(`/campaign/${data[0]._id}`);
        this.props.history.push(`/campaign/${data[0]._id}`);
      })
      .catch((err) => {
        // setBanner(true, "bad", err.message);
      });
  };

  render() {
    return <></>;
  }
}

export default withRouter(CampaignProcess);
