import React, { Component } from 'react'
import html2canvas from 'html2canvas'
import { reactLocalStorage } from 'reactjs-localstorage'
import { ContextMenu, MenuItem, ContextMenuTrigger } from 'react-contextmenu'

import { Dispatcher, Events } from '../Events'
import BannerTextItem from './Elements/BannerText/BannerTextItem'
import BannerImageItem from './Elements/BannerImage/BannerImageItem'
import fonts from './Fonts'
import SendToMixerModal from './SendToMixerModal'

// Styles
import './Styles/preview.scss'
import './Styles/menu.scss'
import Title from '../styles/TitleStyle'
import fetchInstance from '../../utils/fetchInstance'

class BannerCreatorPreview extends Component {
  constructor(props) {
    super(props)

    this.bannerItemComponents = {
      BannerTextItem: BannerTextItem,
      BannerImageItem: BannerImageItem,
    }

    this.cid = new URLSearchParams(window.location.search).get('cid') || null

    this.resolutions = {
      Facebook: ['1200x1200', '1200x628', '1080x1920', '1080x1080'],
      Instagram: ['1200x1200', '1080x1920'],
      Google: ['300x600', '300x250', '728x90', '160x600'],
      Custom: [],
    }

    this.state = {
      identifier: null,
      fonts: {},
      version: '1.7.14',
      resolution: 'Facebook',
      size: '1200x628',
      background: {},
      elements: {},
      data: {},
      setIndex: null,
      mixerModalVisible: false,
      toMixer: {
        size: 300,
        waitElement: null,
        prefix: 'banner',
        html: true,
      },
      campaign: null,
      campaigns: {},
    }

    // return campaigns
    this.campaigns = reactLocalStorage.getObject('campaigns')

    // Set CID
    if (this.cid) {
      this.campaigns[this.cid] = this.campaigns[this.cid]
        ? this.campaigns[this.cid]
        : {}
      reactLocalStorage.setObject('campaigns', this.campaigns)
    }

    reactLocalStorage.setObject('campaigns', this.campaigns)

    Dispatcher.listen(
      Events.ADD_BANNER_ELEMENT,
      this.onAddBannerElement.bind(this),
    )
    Dispatcher.listen(
      Events.REMOVE_BANNER_ELEMENT,
      this.onDeleteBannerElement.bind(this),
    )
    Dispatcher.listen(
      Events.UPDATE_BANNER_ELEMENT,
      this.onUpdateBannerElement.bind(this),
    )
    Dispatcher.listen(
      Events.CHANGE_BANNER_BACKGROUND,
      this.onChangeBannerBackground.bind(this),
    )
    Dispatcher.listen(Events.CAMPAIGN_SELECT, this.selectCampaign.bind(this))
    Dispatcher.listen(Events.SEND_TO_MIXER, this.openMixerModal.bind(this))

    // This should be used in layer editor later
    //Dispatcher.listen(Events.SET_BANNER_ELEMENT_INDEX, this.onChangeBannerBackground.bind(this));
  }

  onMixerHTML = (html) => {
    let toMixer = { ...this.state.toMixer }
    toMixer.html = html

    this.setState({ toMixer })
  }

  onMixerSize = (size) => {
    let toMixer = { ...this.state.toMixer }
    toMixer.size = size

    this.setState({ toMixer })
  }

  onMixerElement = (element) => {
    let toMixer = { ...this.state.toMixer }
    toMixer.waitElement = element

    this.setState({ toMixer })
  }

  onMixerPrefix = (prefix) => {
    let toMixer = { ...this.state.toMixer }
    toMixer.prefix = prefix

    this.setState({ toMixer })
  }

  getBannerIdentifier = () => {
    if (!this.state.identifier) {
      this.state.identifier =
        'banner_' + Math.random().toString(36).substring(7)
      reactLocalStorage.set('current_banner_id', this.state.identifier)
      this.setState({ ...this.state, identifier: this.state.identifier })
    }

    return this.state.identifier
  }

  componentDidMount = () => {
    let id = reactLocalStorage.get('current_banner_id')
    let storage = reactLocalStorage.getObject(id)

    // Restore storage if same version
    if (storage && storage.version == this.state.version) {
      this.setState(storage)
    }

    // Load fonts and set modal to false
    this.setState({
      fonts: fonts.fonts,
      mixerModalVisible: false,
    })

    // Notify everyone about the update
    Dispatcher.dispatch({
      event: Events.UPDATE_BANNER_LAYERS,
      payload: {
        layers: this.state.elements,
      },
    })
  }

  componentDidUpdate = () => {
    let id = this.getBannerIdentifier()
    reactLocalStorage.setObject(id, this.state)

    // Notify everyone about the update
    Dispatcher.dispatch({
      event: Events.UPDATE_BANNER_LAYERS,
      payload: {
        layers: this.state.elements,
      },
    })
  }

  selectCampaign = (payload, event) => {
    this.cid = payload.id

    if (payload.id) {
      this.campaigns[this.cid] = this.campaigns[this.cid]
        ? this.campaigns[this.cid]
        : {}
      reactLocalStorage.setObject('campaigns', this.campaigns)
    }
  }

  onChangeBannerBackground = (payload, event) => {
    let background = payload.file
    let key = this.getBannerSizeKey()

    if (!background || !this.state.background[key])
      this.state.background[key] = {
        src: null,
        size: null,
      }

    if (background) {
      this.state.background[key] = {
        src: background.path,
        size: payload.size,
      }
    }

    this.setState({ background: this.state.background })
  }

  onDeleteBannerElement = (payload, event) => {
    let key = this.getBannerSizeKey()

    let elements = this.state.elements[key].filter((el) => {
      return el.id !== payload.id
    })

    this.state.elements[key] = elements

    this.setState({
      elements: this.state.elements,
    })
  }

  onUpdateBannerElement = (payload, event) => {
    let key = this.getBannerSizeKey()

    if (!this.state.elements[key]) this.state.elements[key] = []

    let elements = this.state.elements[key].map((el) => {
      if (el.id == payload.id) el.options = payload.options
      return el
    })

    this.state.elements[key] = elements

    this.setState({
      elements: this.state.elements,
    })
  }

  onAddBannerElement = (payload, event) => {
    let key = this.getBannerSizeKey()

    if (!this.state.elements[key]) this.state.elements[key] = []

    payload.id = 'banner_element_' + this.state.elements[key].length
    payload.locked = false

    this.state.elements[key].push(payload)

    this.setState({
      elements: this.state.elements,
    })
  }

  onElementChanged = (value, index) => (e) => {
    let key = this.getBannerSizeKey()
    let element = this.state.elements[key][index]

    element.frame = e.frame

    this.state.elements[key][index] = element

    this.setState({
      elements: this.state.elements,
    })
  }

  getBannerSizeKey = () => {
    return this.state.resolution + '_' + this.state.size
  }

  setSize = (e) => {
    this.setState({
      resolution: this.state.resolution,
      size: e.target.value,
    })
  }

  /**
   * Send to server
   * @param {*} e
   */
  sendToMixer = (e) => {
    let { state } = this

    console.log(this.state)

    let key = this.getBannerSizeKey()
    let size = state.size.split('x')

    let elements = document.querySelectorAll('.banner-item')
    var css = ''

    const fontList = Object.values(fonts.fonts).filter((font) => font && font.path);
    fontList.forEach((item) => {
      css += `@font-face { font-family: "${item.name}"; src: url("${item.path}"); } \n`;
    });

    let style = document.createElement('style')

    css += `.omni-root{ width: ${size[0]}px; height:${size[1]}px; position: absolute; } \n`
    css += `.banner-item { position: absolute !important; } \n`

    style.setAttribute('type', 'text/css')
    style.appendChild(document.createTextNode(css))

    let body = document.createElement('body')
    let holder = document.createElement('div')

    body.setAttribute('style', 'margin: 0; padding:0;')
    holder.setAttribute('class', 'omni omni-root')

    body.appendChild(style)
    body.appendChild(holder)

    // Save background
    let background = state.background[key] ? this.state.background[key] : {}

    if (background.src) {
      let backgroundEl = document.createElement('img')

      backgroundEl.setAttribute('class', 'omni omni-backgroundEl')
      backgroundEl.setAttribute(
        'style',
        'position: absolute; left:0; top:0; width: 100%; height:100%; z-index:0',
      )
      backgroundEl.setAttribute('src', background.src)

      holder.appendChild(backgroundEl)

      // holder.style.backgroundImage = `url(${background.src})`;
      // holder.style.backgroundSize = background.size ? background.size : 'cover';
    }

    if (elements.length > 0) {
      // Initialise data
      if (!this.state.data[key]) this.state.data[key] = {}

      elements.forEach((element, index, array) => {
        holder.appendChild(element.cloneNode(1))
      })

      let settings = this.state.toMixer
      let projectID = this.campaigns[this.cid].projectID
        ? this.campaigns[this.cid].projectID
        : null

      // temp check if CID
      if (!this.cid && this.cid === null) {
        return alert(
          'You need to set a campaign before sending this to the mixer',
        )
      }

      let payload = {
        projectID: projectID,
        image_width: size[0] + 'px',
        image_height: size[1] + 'px',
        image_size: settings.size,
        name_prefix: settings.prefix,
        html: body.outerHTML,
      }

      if (settings.html == true) payload.generate_html = 'yes'
      if (settings.waitElement) payload.last_element = settings.waitElement

      fetchInstance(`/creator${this.cid ? `?cid=${this.cid}` : ''}`, {
        method: 'POST',
        body: JSON.stringify(payload),
      })
        .then((res) => {
          if (res.ok) return res.json()
          throw new Error(res.status + ' ' + res.statusText)
        })
        .then(
          (res) => {
            // Remove the temp holder
            body.remove()
            this.campaigns[this.cid].projectID = res.projectID
            this.campaigns[this.cid].redirect = res.redirect
            reactLocalStorage.setObject('campaigns', this.campaigns)

            this.setState({
              data: this.state.data,
              mixerModalVisible: false,
            })

            const baseUrl = window.location.origin;
            const fullUrl = `${baseUrl}${res.redirect}`;
            window.location.href = fullUrl;
          },
          (e) => {
            body.remove()
          },
        )
    } else {
      alert('You need to add at least one element to start mixing')
      this.setState({ mixerModalVisible: false })
      return
    }
  }

  openMixerModal = () => {
    if (this.cid == null) {
      return alert(
        'You need to set a campaign before sending this to the mixer',
      )
    }

    this.setState({
      mixerModalVisible: true,
    })
  }

  handleOpen = () => {
    this.setState({ mixerModalVisible: true })
  }

  handleClose = () => {
    this.setState({ mixerModalVisible: false })
  }

  sendToDesktop = (e) => {
    let key = this.getBannerSizeKey()
    let element = document.getElementById('banner-view')

    html2canvas(element, {
      useCORS: false,
      imageTimeout: 2000,
      removeContainer: true,
    }).then((canvas) => {
      var a = document.createElement('a')
      a.href = canvas
        .toDataURL('image/png')
        .replace('image/png', 'image/octet-stream')
      a.download = key + '.png'
      a.click()
    })
  }

  bringToFront = (keyId, index) => {
    const key = this.getBannerSizeKey()

    if (index < this.state.elements[key].length - 1) {
      this.state.elements[key] = this.array_move(
        this.state.elements[key],
        index,
        index + 1,
      )
    }

    this.setState({
      elements: this.state.elements,
    })
  }

  sendToBack = (keyId, index) => {
    const key = this.getBannerSizeKey()

    if (index > 0) {
      this.state.elements[key] = this.array_move(
        this.state.elements[key],
        index,
        index - 1,
      )
    }

    this.setState({
      elements: this.state.elements,
    })
  }

  onToggleLocked = (item, index) => () => {
    const key = this.getBannerSizeKey()

    item.locked = item.locked == false ? true : false
    this.state.elements[key][index] = item

    this.setState({
      elements: this.state.elements,
    })
  }

  setResolution = (e) => {
    if (e.target.value == 'Custom') {
      this.setState({
        resolution: e.target.value,
        size: '1024x768',
      })
      return
    }

    this.setState({
      resolution: e.target.value,
      size: this.resolutions[e.target.value][0],
    })
  }

  setCustomResolution = (e) => {
    // early return
    if (e.target.value.indexOf('x') === -1) return

    this.setState({
      resolution: this.state.resolution,
      size: e.target.value,
    })
  }

  array_move = (arr, old_index, new_index) => {
    if (new_index >= arr.length) {
      var k = new_index - arr.length + 1
      while (k--) {
        arr.push(undefined)
      }
    }

    arr.splice(new_index, 0, arr.splice(old_index, 1)[0])

    return arr
  }

  removeBackground = (e) => {
    if (!window.confirm(`Are you shure you want to remove the background ?`))
      return

    let key = this.getBannerSizeKey()
    this.state.background[key] = {}

    this.setState({
      background: this.state.background,
    })
  }

  removeElements = (e) => {
    if (!window.confirm(`Are you shure you want to all elements ?`)) return

    let key = this.getBannerSizeKey()

    this.state.elements[key] = []

    this.setState({
      elements: this.state.elements,
    })
  }

  destroy = (e) => {
    if (
      !window.confirm(`Are you shure you want to destory ALL BANNERS stored ?`)
    )
      return

    reactLocalStorage.remove('current_banner_id')
    reactLocalStorage.remove('fonts')
    reactLocalStorage.remove('campaigns')

    this.setState({
      elements: [],
      background: {},
    })
  }

  alignCenter = (id, index) => {
    let key = this.getBannerSizeKey()
    const el = document.getElementById(id)

    el.style.top = '50%'
    el.style.left = '50%'
    el.style.transform = 'translate(-50%, -50%)'

    let newState = { ...this.state }
    newState.elements[key][index].frame.top = '50%'
    newState.elements[key][index].frame.left = '50%'
    newState.elements[key][index].frame.transform.translate[0] = '-50%'
    newState.elements[key][index].frame.transform.translate[1] = '-50%'

    this.setState(newState)
  }

  render() {
    const { state } = this

    const {
      sendToDesktop,
      bringToFront,
      sendToBack,
      alignCenter,
      removeElements,
      removeBackground,
      onToggleLocked,
      onElementChanged,
      destroy,
    } = this

    let size = state.size.split('x')
    let key = this.getBannerSizeKey()

    let projectID = this.state.data[key] ? this.state.data[key].projectID : ''

    let elements = state.elements[key] ? state.elements[key] : []
    let background = state.background[key] ? state.background[key] : {}

    let bannerstyles = {
      position: 'relative',
      width: `${size[0]}px`,
      height: `${size[1]}px`,
      backgroundSize: '100% 100%',
      margin: '30px auto 0',
      backgroundImage: background.src ? `url(${background.src})` : 'none',
      border: '1px solid rgb(224, 224, 224)',
    }

    const renderComponents = elements.map((item, index) => {
      let BannerItemComponent = this.bannerItemComponents[item.type]

      return (
        <BannerItemComponent
          id={item.id}
          key={item.id}
          type={item.type}
          options={item.options}
          locked={item.locked}
          zindex={index}
          frame={item.frame}
          autosize={item.type === 'BannerTextItem' ? true : false}
          container={document.querySelector('.banner-preview')}
          offsetH={state.size.split('x')[1]}
          offsetW={state.size.split('x')[0]}
          onChanged={onElementChanged(item, index)}
          onToggleLocked={onToggleLocked(item, index)}
          onCenterAlign={() => {
            alignCenter(item.id, index)
          }}
          bringToFront={() => {
            bringToFront(item.id, index)
          }}
          sendToBack={() => {
            sendToBack(item.id, index)
          }}
        />
      )
    })

    let resolution = state.resolution

    return (
      <ContextMenuTrigger
        attributes={{ className: 'context-menu-trigger' }}
        id={'preview_context_holder'}
      >
        <div className="context-menu-trigger">
          <Title>
            {this.state.campaign && this.state.campaign.name}Banner Creator
          </Title>

          <div style={{ display: 'flex' }}>
            <select
              style={{ marginRight: '20px' }}
              id="renderRes"
              name="background-sizes"
              className="custom-select"
              value={state.resolution}
              onChange={this.setResolution}
            >
              {Object.keys(this.resolutions).map((res, index) => (
                <option key={index} value={res}>
                  {res}
                </option>
              ))}
            </select>

            {resolution !== 'Custom' && (
              <select
                id="renderResSize"
                className="custom-select"
                name="background-sizes"
                value={state.size}
                onChange={this.setSize}
              >
                {this.resolutions[resolution].map((size, index) => (
                  <option key={index} value={size}>
                    {size}
                  </option>
                ))}
              </select>
            )}

            {resolution === 'Custom' && (
              <input
                id="renderCustomRes"
                style={{ width: '100px', textAlign: 'center' }}
                value={state.size}
                onChange={this.setCustomResolution}
                type="text"
              />
            )}
          </div>

          <div style={{ float: 'right' }}>
            <div className="debug">
              <b>DEBUG</b>
              <br />
              Mixer ID: <b style={{ width: '300px' }}>{projectID}</b>
              <br />
              Version: <b style={{ width: '300px' }}>{state.version}</b>
              <br />
              Identifier: <b style={{ width: '300px' }}>{state.identifier}</b>
            </div>
          </div>

          <SendToMixerModal
            handleSendToMixer={this.sendToMixer}
            handleClose={this.handleClose}
            onSize={this.onMixerSize}
            onElement={this.onMixerElement}
            onPrefix={this.onMixerPrefix}
            onHtml={this.onMixerHTML}
            open={this.state.mixerModalVisible}
          />

          <div
            className="container banner-preview-container"
            style={{ minWidth: '600px', minHeight: '350px' }}
          >
            <div
              id="banner-view"
              className="banner-preview"
              style={bannerstyles}
            >
              {renderComponents}
            </div>

            <ContextMenu id={'preview_context_holder'}>
              <MenuItem onClick={this.openMixerModal}>Send to Mixer</MenuItem>
              <MenuItem onClick={sendToDesktop}>Save as PNG</MenuItem>
              <MenuItem divider />
              <MenuItem onClick={removeElements}>Remove elements</MenuItem>
              <MenuItem onClick={removeBackground}>Remove background</MenuItem>
              <MenuItem divider />
              <MenuItem onClick={destroy}>Reset</MenuItem>
            </ContextMenu>
          </div>
        </div>
      </ContextMenuTrigger>
    )
  }
}

export default BannerCreatorPreview
