import React, { useState } from 'react';
import FormStyles from '../styles/FormStyles';
import Title from '../styles/TitleStyle';
import { MdDelete, MdClose } from 'react-icons/md';
import GeneralModalStyles from '../styles/GeneralModalStyles';
import ManageGroupsStyle from '../styles/ManageGroupsStyle';
import { SketchPicker } from 'react-color';
import reactCSS from 'reactcss';

const ManageGroupsModal = ({
  setBanner,
  handleCloseModal,
  campaignGroups,
  saveGroups,
}) => {
  const [newGroupName, setNewGroupName] = useState('');
  const [groups, setGroups] = useState([
    ...campaignGroups.filter(
      (group) => group.name !== 'Rejected' && group.name !== 'Ungrouped',
    ),
  ]);
  const [currentGroupName, setCurrentGroupName] = useState('');
  const [displayColorPicker, setDisplayColorPicker] = useState([]);
  const [color, setColor] = useState(
    '#' +
      ('FFFFFF' + Math.floor(Math.random() * 16777215).toString(16)).slice(-6),
  );

  const handleInputChange = (e) => {
    setNewGroupName(e.currentTarget.value);
  };

  const removeFromGroup = (groupName) => {
    setGroups(groups.filter((group) => group.name !== groupName));
  };

  const handleClick = (i, groupName) => {
    setCurrentGroupName(groupName);

    let newColorPicker = [...displayColorPicker];
    newColorPicker[i] = true;
    setDisplayColorPicker(newColorPicker);
  };

  const handleClose = () => {
    setDisplayColorPicker([false]);
  };

  const handleColor = (color, event) => {
    setColor(color.hex);
    handleClose();
  };

  const handleColorEdit = (color) => {
    let tempGroups = [...groups];
    let objIndex = tempGroups.findIndex(
      (group) => group.name === currentGroupName,
    );
    tempGroups[objIndex].color = color.hex;
    setGroups(tempGroups);
    handleClose();
  };

  const addGroup = (e) => {
    e.preventDefault();

    let groupName = newGroupName;
    if (groupName.length === 0) {
      return setBanner(true, 'bad', 'New group must have a name.');
    }
    if (groupName === 'Rejected' || groupName === 'Ungrouped') {
      return setBanner(
        true,
        'bad',
        "That's a reserved name. Please use a different one.",
      );
    }

    const tempGroups = [...groups];
    const [found] = tempGroups.filter((group) => group.name === groupName);
    if (found) {
      return setBanner(true, 'bad', 'Group name already exists.');
    }

    tempGroups.push({ name: groupName, images: new Set([]), color });
    setGroups(tempGroups);
  };

  const save = (e) => {
    e.preventDefault();

    saveGroups(groups);
    handleCloseModal();
  };

  const styles = reactCSS({
    default: {
      color: {
        width: '100%',
        height: '100%',
        borderRadius: '2px',
        background: `${color}`,
      },
      swatch: {
        padding: '5px',
        background: '#fff',
        borderRadius: '5px',
        boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
        display: 'inline-block',
        cursor: 'pointer',
        width: '32px',
        height: '32px',
      },
      swatch2: {
        padding: '3px',
        background: '#fff',
        display: 'inline-block',
        cursor: 'pointer',
        width: '24px',
        height: '24px',
      },
      popover: {
        position: 'absolute',
        zIndex: '2',
      },
      cover: {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
      },
    },
  });

  return (
    <GeneralModalStyles className="modal-wrapper">
      <div className="modal-body" style={{ width: '350px' }}>
        <ManageGroupsStyle>
          <div onClick={handleCloseModal} className="modal-close">
            <MdClose className="action-button button-delete" />
          </div>

          <FormStyles>
            <Title>Manage Groups</Title>

            <div data-testid="groups-list" className="group-list">
              {groups.map((group, i) => {
                // if(group.name !== 'Rejected' && group.name !== 'Ungrouped'){
                return (
                  <div className="group-wrapper" key={i}>
                    <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                      <div
                        style={styles.swatch2}
                        onClick={() => handleClick(i, group.name)}
                      >
                        <div
                          style={{
                            width: '100%',
                            height: '100%',
                            borderRadius: '2px',
                            background: group.color,
                          }}
                        />
                      </div>
                      {displayColorPicker[i] ? (
                        <div style={styles.popover}>
                          <div style={styles.cover} onClick={handleClose} />
                          <SketchPicker
                            color={group.color}
                            onChange={handleColorEdit}
                            disableAlpha={true}
                          />
                        </div>
                      ) : null}
                      <label
                        style={{ marginLeft: '5px', lineHeight: '24px' }}
                        className="group"
                      >
                        {group.name}
                      </label>
                    </div>

                    <div
                      onClick={() => removeFromGroup(group.name)}
                      className="action-button button-delete"
                    >
                      <MdDelete />
                    </div>
                  </div>
                );
                // }
              })}
            </div>

            <div className="add-group-wrapper">
              <input
                type="text"
                name="newGroupName"
                placeholder="Name"
                onChange={handleInputChange}
                value={newGroupName}
              />
              <div style={styles.swatch} onClick={() => handleClick('new')}>
                <div style={styles.color} />
              </div>
              {displayColorPicker['new'] ? (
                <div style={styles.popover}>
                  <div style={styles.cover} onClick={handleClose} />
                  <SketchPicker
                    color={color}
                    onChange={handleColor}
                    disableAlpha={true}
                  />
                </div>
              ) : null}
              <button
                className="confirm-button"
                onClick={addGroup}
                style={{ width: '110px' }}
              >
                Add Group
              </button>
            </div>

            <div className="button-wrapper">
              <button className="confirm-button" onClick={save}>
                Save
              </button>
            </div>
          </FormStyles>
        </ManageGroupsStyle>
      </div>
    </GeneralModalStyles>
  );
};

export default ManageGroupsModal;
