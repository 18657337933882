import React from 'react';

import { Link } from 'react-router-dom';
import ClientContext from './ClientContext';
import PreviewStyles from './styles/PreviewStyles';
import CampaignContext from './CampaignContext';

export default class ClientPreview extends React.Component {
  handleError = (err) =>
    this.props.setBanner(true, 'bad', err.message, false, 5000);

  render() {
    let clientFormFields = null;
    let context = this.context;
    if (this.context.activeClient)
      clientFormFields = (
        <React.Fragment>
          <div className="main" data-testid="client-overview">
            <div className="chapter">
              <h3>Overview</h3>
              <div className="group">
                <input
                  className="formInput"
                  type="text"
                  name="name"
                  value={context.activeClient.name || ''}
                  onChange={context.handlePreviewInputChange}
                  required
                />
                <label>Name</label>
                <span />
              </div>

              <label>Notes</label>
              <textarea
                name="notes"
                onChange={context.handlePreviewInputChange}
                className="formInput"
                value={context.activeClient.notes || ''}
              />
              <button
                type="button"
                className="dangerButton"
                onClick={context.deleteClient}
              >
                Delete Client
              </button>
            </div>
          </div>

          <div className="tools">
            <div className="chapter">
              <h3>Campaigns</h3>

              <CampaignContext.Consumer>
                {(CampaignContext) => (
                  <ul
                    data-testid="client-campaigns"
                    style={{
                      listStyle: 'none',
                      maxHeight: '60vh',
                      overflowY: 'auto',
                    }}
                    className="campaignsList"
                    onClick={(ev) => CampaignContext.setActiveCampaign(ev)}
                  >
                    {!this.context.activeClient._id
                      ? null
                      : CampaignContext.campaigns &&
                        CampaignContext.campaigns
                          .filter(
                            (campaign) =>
                              campaign.client &&
                              campaign.client._id ===
                                this.context.activeClient._id,
                          )
                          .map((c, i) => (
                            <Link to="/campaign" data-id={c._id} key={c._id}>
                              <li className="campaign" data-id={c._id} key={i}>
                                {c.name}
                              </li>{' '}
                            </Link>
                          ))}
                  </ul>
                )}
              </CampaignContext.Consumer>
            </div>
          </div>
          <div className="save">
            <input
              id="formSaveButton"
              type="submit"
              value="Save"
              disabled={!context.unsavedContent}
            />
          </div>
        </React.Fragment>
      );

    return (
      <PreviewStyles
        data-testid="client-preview"
        className="campaignInfoForm"
        onChange={context.updateUnsavedContent}
        onSubmit={context.saveClient}
      >
        {this.context.activeClient ? clientFormFields : null}
      </PreviewStyles>
    );
  }
}

ClientPreview.contextType = ClientContext;
