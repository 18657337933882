import React, { Component } from 'react';

import './FileUploader.scss';
import fetchInstance from '../../utils/fetchInstance';

class FileUploader extends Component {
  static defaultProps = {
    multiple: false,
    persistent: null,
    required: false,
    label: 'Upload an image',
    extensions: '.png,.jpg,.jpeg,.svg,.gif',
    preview: 'true',
    onChange: () => {},
  };

  constructor(props) {
    super(props);

    this.persistent = props.persistent;
    this.multiple = props.multiple;
    this.onChange = props.onChange;
    this.onRefreshed = props.onRefreshed;
    this.original = props.original ? props.original : false;

    this.state = {
      refreshForm: props.refreshForm,
      files: this.props.files ? this.props.files : null,
      uploads: this.props.uploads ? this.props.uploads : null,
    };
  }

  onChangeHandler = (event) => {
    let files = event.target.files;

    const form = new FormData();

    form.append('persistent', this.persistent);
    form.append('multiple', this.multiple);
    form.append('original', this.original);

    if (files.length > 0) {
      for (var i = 0; i < files.length; i++) {
        form.append('files', files[i]);
      }

      fetchInstance('/store', {
        method: 'POST',
        body: form,
      })
        .then((res) => res.json())
        .then((res) => {
          // then print response status

          if (!res.files) return;

          this.state.uploads = res.files;

          this.setState({
            files: files,
            uploads: this.state.uploads,
          });

          // Pass to above
          if (this.onChange) {
            this.onChange(this.state, event);
          }
        })
        .catch((err) => {
          console.error('Error uploading files:', err);
        });
    }
  };

  renderThumbnails = () => {
    if (!this.state.uploads) return;

    return this.state.uploads.map((image) => (
      <img
        className="thumbnail"
        style={{ marginTop: '1rem' }}
        key="image-{image.filename}"
        src={image.path}
        width="auto"
        height="100px"
      />
    ));
  };

  componentWillReceiveProps({ refreshForm }) {
    if (refreshForm !== this.state.refreshForm) {
      this.setState({
        files: this.props.files ? this.props.files : null,
        uploads: this.props.uploads ? this.props.uploads : null,
      });
      this.onRefreshed();
    }
  }

  render() {
    const { props } = this;
    const { onChangeHandler, renderThumbnails } = this;

    const thumbStyle = {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: renderThumbnails() ? '' : 'center',
    };

    return (
      <div className="file-uploader file-uploader-root">
        <label className="btn-default" htmlFor="bg-img">
          {props.label}
        </label>
        <input
          accept={props.extensions}
          style={{ display: 'none' }}
          id="bg-img"
          type="file"
          className="form-control"
          {...props}
          onChange={onChangeHandler}
        />
        {props.preview === 'true' &&
        this.state.uploads &&
        this.state.uploads.length >= 1 ? (
          <div style={thumbStyle} className="thumbnails">
            {renderThumbnails()}
          </div>
        ) : (
          ''
        )}
      </div>
    );
  }
}

export default FileUploader;
