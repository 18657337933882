import React, { useState, useEffect } from 'react';
import FormStyles from '../styles/FormStyles';
import Title from '../styles/TitleStyle';
import { MdClose } from 'react-icons/md';
import GeneralModalStyles from '../styles/GeneralModalStyles';
import fetchInstance from '../../utils/fetchInstance';

const obj = {
  active: false,
  endDate: '',
  startDate: '',
  domain: '',
  name: '',
  notes: '',
  publikLink: {
    expires: {},
  },
  parent: null,
  isCampaignGroup: false,
};

const CampaignDetails = ({
  selectedCampaign,
  handleCloseModal,
  updateCampaign,
  showModal,
  setBanner,
  extractCSRF,
  clientId,
  userId,
  parentCampaign,
  isCampaignGroup = false,
}) => {
  const [currentCampaign, setCurrentCampaign] = useState(
    selectedCampaign || obj,
  );
  const [campaignGroups, setCampaignGroups] = useState([]);
  const [parentId, setParentId] = useState(null);

  useEffect(() => {
    fetchCampaignGroups();
    if (parentCampaign) {
      setParentId(parentCampaign._id);
    }
  }, []);
  const fetchCampaignGroups = async () => {
    try {
      const res = await fetchInstance('/getClientCampaigns', {
        method: 'POST',
        body: JSON.stringify({ id: clientId, isCampaignGroup: true }),
      });
      if (!res.ok) throw new Error(res.statusText);
      const data = await res.json();

      if (data.error) {
        throw new Error(data.message);
      }
      setCampaignGroups(data);
    } catch (err) {
      setBanner(true, 'bad', err.message);
    }
  };

  const handlePreviewInputChange = (ev) => {
    // use cases:
    // 1. Create campaign: isCampaignGroup: can be false for simple campaign or true for campaign group
    // 2. Edit campaign: isCampaignGroup: true if the currentCampaign is group; a simple campaign cannot be converted to group!
    const editCampaign = { ...currentCampaign };

    const changedProperty = ev.currentTarget.name;
    let newValue = ev.currentTarget.value;

    if (ev.currentTarget.type === 'checkbox') {
      newValue = ev.currentTarget.checked;
    }

    // required in case of parent selector does not changed
    if (parentId) {
      editCampaign['parent'] = parentId;
    }

    if (ev.currentTarget.name === 'parent') {
      if (ev.currentTarget.value === '') {
        newValue = null;
      }

      if (parentId !== ev.currentTarget.value) {
        console.log(
          'Are you sure you want to add campaign for other campaign group?',
        );
      }
      setParentId(newValue);
    }

    if (isCampaignGroup) {
      editCampaign['isCampaignGroup'] = isCampaignGroup;
    }

    editCampaign[changedProperty] = newValue;
    setCurrentCampaign(editCampaign);
  };

  const saveCampaign = (ev) => {
    ev.stopPropagation();
    ev.preventDefault();

    // this.setState({ previewBusy: true });
    let campaign = { ...currentCampaign };
    campaign.client = clientId;
    campaign.created_by = userId;

    new Promise((resolve, reject) => {
      const method = !campaign._id ? 'post' : 'put';
      resolve(
        fetchInstance('/campaign', {
          method: method,
          body: JSON.stringify({ campaign: campaign }),
        }),
      );
    })
      .then((res) => {
        if (!res.ok) throw new Error(res.statusText);
        return res.json();
      })
      .then((data) => {
        if (data.success) return data.campaign;
        if (data.error) throw new Error(data.message);
      })
      .then((updatedCampaign) => {
        handleCloseModal();
        updateCampaign(updatedCampaign);

        setBanner(true, 'good', 'Campaign updated successfully');
      })
      .catch((err) => {
        setBanner(true, 'bad', err.message, false);
      });
  };

  return (
    <GeneralModalStyles
      className="modal-wrapper"
      style={{ opacity: showModal ? 1 : 0 }}
    >
      <div className="modal-body">
        <div onClick={handleCloseModal} className="modal-close">
          <MdClose className="action-button button-delete" />
        </div>

        <FormStyles onSubmit={saveCampaign}>
          <Title>
            {selectedCampaign
              ? `Edit ${selectedCampaign.name}`
              : `Add new campaign ${isCampaignGroup ? 'group' : ''}`}
          </Title>
          <div className="group">
            <label>Name</label>
            <input
              className="formInput"
              type="text"
              name="name"
              value={currentCampaign.name}
              onChange={handlePreviewInputChange}
              required
            />
          </div>

          {!isCampaignGroup && (
            <div className="group">
              <label>Campaign Group</label>
              <select name="parent" onChange={handlePreviewInputChange}>
                {parentCampaign ? (
                  <>
                    <option disabled value="">
                      -- Choose a campaign group --
                    </option>{' '}
                    {/* this should not be..disabled in case user want to convert subcampaign into campaign */}
                    <option value={parentCampaign._id}>
                      {parentCampaign.name}
                    </option>
                    <option value="">Ungroup this campaign</option>
                  </>
                ) : (
                  <option value="">-- Choose a campaign group --</option>
                )}
                {campaignGroups &&
                  campaignGroups.map((campaign) => {
                    if (
                      (selectedCampaign &&
                        selectedCampaign._id === campaign._id) ||
                      (parentCampaign && parentCampaign._id === campaign._id)
                    ) {
                      return;
                    }
                    return (
                      <option key={campaign._id} value={campaign._id}>
                        {campaign.name}
                      </option>
                    );
                  })}
              </select>
            </div>
          )}

          <div className="group">
            <label>Domain</label>
            <input
              className="formInput"
              type="text"
              name="domain"
              value={currentCampaign.domain}
              onChange={handlePreviewInputChange}
              required
            />
          </div>

          <div
            className="group"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <label>Status</label>
            <div style={{ marginLeft: '1rem' }}>
              <input
                id="singleToggle"
                name="active"
                type="checkbox"
                checked={!!currentCampaign.active}
                onChange={handlePreviewInputChange}
              />
              <label htmlFor="singleToggle" />
            </div>
          </div>

          <div className="group">
            <label>Campaign Start Date</label>
            <input
              name="startDate"
              type="date"
              value={
                currentCampaign && currentCampaign.startDate
                  ? new Date(currentCampaign.startDate)
                      .toISOString()
                      .substr(0, 10)
                  : ''
              }
              onChange={handlePreviewInputChange}
            />
          </div>

          <div className="group">
            <label>Campaign End Date</label>
            <input
              name="endDate"
              type="date"
              min={currentCampaign.startDate}
              value={
                currentCampaign && currentCampaign.endDate
                  ? new Date(currentCampaign.endDate)
                      .toISOString()
                      .substr(0, 10)
                  : ''
              }
              onChange={handlePreviewInputChange}
            />
          </div>

          <div className="group">
            <label>Notes</label>
            <textarea
              name="notes"
              onChange={handlePreviewInputChange}
              className="formInput"
              value={currentCampaign.notes}
            />
          </div>

          <div className="button-wrapper">
            <button className="confirm-button">Save</button>
          </div>
        </FormStyles>
      </div>
    </GeneralModalStyles>
  );
};

export default CampaignDetails;
